@import '~styleguide/variables';

.ColorSelector {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
	margin-bottom: 16px;
	cursor: pointer;

	&:last-of-type {
		margin-right: 0;
	}

	&__swatch {
		box-sizing: border-box;
		width: 44px;
		height: 44px;
		margin-bottom: 10px;
		border: 0.5px solid rgba($black, 0.5);
		border-radius: 50%;
		box-shadow: 0 4px 16px rgba($black, 0.12);

		&--white {
			@extend .ColorSelector__swatch;

			background-color: #f9f6f0;
		}

		&--black {
			@extend .ColorSelector__swatch;

			background-color: #191d23;
		}

		&--grey {
			@extend .ColorSelector__swatch;

			background-color: #4e5c54;
		}

		&--green {
			@extend .ColorSelector__swatch;

			background-color: #57bd84;
		}

		&--coral {
			@extend .ColorSelector__swatch;

			background-color: #f4805c;
		}

		&--indigo {
			@extend .ColorSelector__swatch;

			background-color: #2c4866;
		}

		&--blue {
			@extend .ColorSelector__swatch;

			background-color: #0091c7;
		}
	}

	&__input {
		visibility: hidden;
		position: absolute;
		width: 0;
		height: 0;
		opacity: 0;
	}

	&__text {
		display: none;
		font-size: 12px;
		font-weight: 600;
		line-height: 16px;
	}

	&--active,
	&:focus,
	&:active {
		outline: none;

		@extend .ColorSelector;

		.ColorSelector__swatch {
			border: 2px solid $white;
			box-shadow: 0 0 0 2px $color-primary, 0 4px 16px rgba($black, 0.12);
		}

		.ColorSelector__text {
			display: block;
		}
	}

	&:hover {
		.ColorSelector__text {
			display: block;
		}
	}
}
