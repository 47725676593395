@import '~styleguide/variables';

.SignedPayloadsSection {
	&__heading {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 42px;
	}

	&__description {
		margin-top: 10px;
		padding-bottom: 24px;
	}

	&__body {
		margin-bottom: 80px;
	}
}
