
@import '~styleguide/variables';

.CardTransaction {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 64px;
	padding-right: 28px;
	border-bottom: 1px solid $gray--100;
	cursor: pointer;

	&::after {
		content: '';
		display: inline-block;
		position: absolute;
		right: 8px;
		width: 8px;
		height: 12px;
		background-image: url('./chevron-right.svg');
		background-repeat: no-repeat;
		background-position: center;
	}
}
