@import '~styleguide/variables';

.LoadStep {
	width: 100%;
	margin-bottom: 24px;
	transform: translateZ(0);

	@media all and (min-width: $responsive-tablet) {
		border-radius: 16px;
		box-shadow: $shadow;
	}

	&__text {
		display: inline-block;
	}

	&__cta {
		width: 100%;
		margin-top: 20px;
	}

	&__link {
		font-size: 14px;
		font-weight: normal;
		line-height: 20px;
	}

	&__row {
		margin-top: 28px;
		text-align: center;
	}
}
