@import '~styleguide/variables';

.ModalIdle {
	.Warning {
		padding: 20px;
		border-radius: 8px;
		background-color: $red--50;

		* {
			color: $red--600;
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 40px;
		padding-top: 16px;

		*:first-child {
			margin-right: 16px;
		}
	}
}
