@import '~styleguide/variables';

.PercentageInput {
  position: relative;
  width: 100%;

  &::after {
    content: '%';
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 28px;
    padding: 0 16px 2px 16px;
    font-size: 16px;
    line-height: 20px;
    box-sizing: border-box;
  }
}

.PercentageInput__label {
  position: absolute;
  z-index: 1;
  top: 14px;
  left: 16px;
  transition: all 0.2s;
  border: none;
  color: $gray--500;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  pointer-events: none; // This is positioned on top of the input
}

.PercentageInput__input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  padding: 22px 16px 2px 16px;
  border: 1px solid $gray--200;
  border-radius: 8px;
  outline: none;
  color: transparent;
  font-size: 16px;
  line-height: 20px;

  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:hover {
    border: 1px solid $black;
  }

  &:active,
  &:focus {
    border: 1px solid $color-primary;

    & + .PercentageInput__label {
      color: $color-primary;
    }
  }
}

.PercentageInput:focus-within,
.PercentageInput.has-content {
  .PercentageInput__label {
    top: 1px; // Place it inside input border
    right: 12px; // Let space for the scrollbar on textarea
    left: 1px;
    padding: 10px 0 0 16px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 1;
  }

  .PercentageInput__input {
    color: $black;
    font-size: 16px;
  }

  &::after {
    display: block;
  }
}

.PercentageInput.is-disabled {
  .PercentageInput__input {
    border-color: $gray--50;
    background-color: $gray--50;
    color: $text__color--disabled;
  }

  .PercentageInput__label {
    color: $text__color--disabled;
  }
}

.PercentageInput.has-error {
  .PercentageInput__input {
    border-color: $color-negative;
    font-size: 16px;
  }

  .PercentageInput__error {
    display: block;
    position: absolute;
    z-index: 1;
    top: 1px;
    right: 16px;
    padding-top: 6px;
    color: $color-negative;
    font-size: 12px;
    line-height: 20px;
  }
}
