@import '~styleguide/variables';
@import '~styleguide/utilities/mixins';
$offset-top: 2.5rem;
$offset-bottom: 32px;
$header-height: 72px;

.SlideCard {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  border-radius: 16px;
  background-color: $white;
  box-shadow: $shadow;

  @media all and (min-width: $responsive-tablet) {
    position: relative;
    top: -40px;
    height: auto;
    max-height: calc(100vh - #{$offset-top} - #{$offset-bottom} - #{$header-height});
  }

  @media all and (min-width: 1000px) {
    width: auto;
  }
}

.SlideCard__header {
  display: flex;
  width: 100%;
  padding: 14px 20px;
}

.SlideCard__close {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  margin-right: 16px;
  padding: 0;
  border: 0;
  border-radius: 50%;
  background-color: $gray--50;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $gray--100;
  }

  @include focus();
}

.SlideCard__body {
  flex: 1 1 auto;
  padding-right: 20px;
  padding-left: 20px;
  overflow-y: scroll;
}

.SlideCard__footer {
  flex: 0 0 auto;
  padding: 16px 20px;
  border-top: 1px solid $gray--100;
}

.SlideCard--right-enter {
  left: 100%;
}

.SlideCard--right-enter-active {
  left: 0;
  transition: left 0.4s;
}

.SlideCard--right-exit {
  left: 0;
}

.SlideCard--right-exit-active {
  left: 100%;
  transition: left 0.4s;
}

.SlideCard--left-enter {
  left: -100%;
}

.SlideCard--left-enter-active {
  left: 0;
  transition: left 0.4s;
}

.SlideCard--left-exit {
  left: 0;
}

.SlideCard--left-exit-active {
  left: -100%;
  transition: left 0.4s;
}
