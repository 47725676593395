.FullNameFormatter {
	&__name {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__initials {
		margin-right: 12px;
	}
}
