@import "~styleguide/variables";
@import "~styleguide/utilities/functions";
@import "~styleguide/utilities/mixins";

.ChevronPagination {
  &--block {
    width: 100%;
    margin: 28px 0;
    text-align: right;
  }
}

.ChevronPagination__button {
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 50%;
  background: $gray--50;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: 0;
    background-color: $black;
  }

  &:active {
    outline: 0;
    background-color: $gray--700;
  }

  &--prev {
    composes: ChevronPagination__button;
    margin-right: 12px;

    @include chevron-left(encode-color($black));

    &:hover,
    &:focus,
    &:active {
      @include chevron-left(encode-color($white));
    }

    &:disabled {
      @include chevron-left(encode-color($gray--400));

      background-color: $gray--50;
    }
  }

  &--next {
    composes: ChevronPagination__button;

    @include chevron-right(encode-color($black));

    &:hover,
    &:focus,
    &:active {
      @include chevron-right(encode-color($white));
    }

    &:disabled {
      @include chevron-right(encode-color($gray--400));

      background-color: $gray--50;
    }
  }
}
