@import '~styleguide/variables';

.Diagnostics {
	height: 100vh;
	width: 100vw;

	&__background {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;

		&--positive {
			composes: Diagnostics__background;

			background: conic-gradient(
				from 112.2deg at 50% 50%,
				#f67e65 -48.8deg,
				#6022ff 68.75deg,
				#3d7eff 109.72deg,
				#0055ff 140.62deg,
				#00a96d 191.82deg,
				#00df90 241.51deg,
				#00df90 278.34deg,
				#f67e65 311.2deg,
				#6022ff 428.75deg
			);
			transform: matrix(0.91, -0.41, -0.41, -0.91, 0, 0) scale(4) translate(-2%, -13%);
		}

		&--negative {
			composes: Diagnostics__background;

			background: $black;
		}
	}

	&__container {
		width: 100%;
		height: 100%;
		padding: 100px;
		display: grid;
		grid-template-columns: 1fr;

		@media all and (min-width: $breakpoint--lg) {
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 40px;
		}

		@media all and (min-width: 1500px) {
			padding: 100px 200px;
		}

		&--negative {
			composes: Diagnostics__container;

			padding-right: 0;
		}
	}

	&__cards {
		display: none;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center right;

		@media all and (min-width: $breakpoint--lg) {
			display: block;
		}

		&--positive {
			composes: Diagnostics__cards;

			background-image: url(./images/cards-positive.svg);
		}

		&--negative {
			composes: Diagnostics__cards;

			background-image: url(./images/cards-negative.svg);
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	&__logo {
		margin-bottom: 160px;
	}

	&__title {
		margin-top: 40px;
		margin-bottom: 24px;
		font-size: 52px;
		line-height: 64px;
		font-weight: 700;
		color: $white;
	}

	&__copy {
		font-size: 24px;
		line-height: 32px;
		font-weight: 600;
		color: $white;
	}
}
