@import '~styleguide/variables';

.AddPublicKeySection {
	&__heading {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 50px;
	}

	&__body {
		border-top: 1px solid $gray--100;
		border-bottom: 1px solid $gray--100;
		padding: 40px 0;
		margin: 22px 0 40px;
	}
}
