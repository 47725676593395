@import '~styleguide/variables';

.ActivateAccount {
	display: flex;
	flex: 1;
	flex-direction: row;

	&__aside {
		display: none;
		flex-direction: column;
		width: 320px;
		padding: 40px 34px;

		@media all and (min-width: $responsive-tablet) {
			display: flex;
		}
	}

	&__content {
		max-width: 1200px;
		padding: 2.5rem 4rem;
	}
}

.Counter {
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	margin-bottom: 44px;

	&__current {
		margin-right: 8px;
		color: $black;
		font-size: 52px;
		font-weight: bold;
		line-height: 52px;
	}

	&__total {
		color: $gray--400;
		font-size: 14px;
		line-height: 28px;
	}
}

.Nav {
	display: flex;
	flex-direction: column;

	&__link {
		margin-bottom: 24px;
		color: $gray--400;
		font-size: 16px;
		line-height: 20px;
		text-decoration: none;
		pointer-events: none;

		&--isActive {
			color: $color-primary;
			font-weight: 600;
			pointer-events: initial;
		}
	}
}
