@import '~styleguide/variables';

.Link {
	color: $gray--900;
	font-size: 14px;
	font-style: normal;
	font-weight: normal;
	line-height: 20px;
	text-decoration: none;

	&.isUnderline {
		border-bottom: 1px solid currentColor;
	}

	&.isMuted {
		color: $gray--500;
	}

	&.isBlack {
		color: $black;
	}

	&.isPrimary {
		color: $color-primary;
	}

	&.isNegative {
		color: $color-negative;
	}

	&.isButton {
		display: inline-block;
		align-items: center;
		height: 52px;
		padding: 14px 24px;
		border: none;
		border-radius: 8px;
		background: $color-primary;
		color: $white;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		text-align: center;
		text-decoration: none;
		cursor: pointer;

		&:focus,
		&:hover,
		&.isBlack {
			background-color: $black;
			color: $white;
		}

		&:active {
			background-color: $gray--700;
		}
	}

	&.isSecondary {
		@extend .isButton;

		border: 1px solid $color-primary;
		background-color: transparent;
		color: $color-primary;

		&:focus,
		&:hover,
		&.isBlack {
			border-color: $black;
			background-color: transparent;
			color: $black;
		}

		&:active {
			border-color: $gray--700;
			color: $gray--700;
		}
	}

	&.isRounded {
		@extend .isButton;

		height: 44px;
		padding: 12px 20px;
		border-radius: 100px;
		font-size: 16px;
		line-height: 20px;
	}
}
