.PaymentMethodPreview {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.PaymentMethodPreview__card {
	width: 28px;
	height: 24px;
	margin-right: 12px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	&--mastercard {
		composes: PaymentMethodPreview__card;
		background-image: url('images/mastercard.svg');
	}

	&--visa {
		composes: PaymentMethodPreview__card;
		background-image: url('images/visa.svg');
	}
}
