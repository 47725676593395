@import '~styleguide/variables';

.Initials {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background-color: $gray--100;
}

.Initials__content {
	font-family: $font-family;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px;
}

.Initials__content--orange {
	@extend .Initials__content;

	color: $orange--300;
}

.Initials__content--green {
	@extend .Initials__content;

	color: $green--300;
}

.Initials__content--gray {
	@extend .Initials__content;

	color: $gray--300;
}

.Initials__content--yellow {
	@extend .Initials__content;

	color: $yellow--300;
}

.Initials__content--blue {
	@extend .Initials__content;

	color: $blue--300;
}

.Initials__content--purple {
	@extend .Initials__content;

	color: $purple--300;
}

.Initials--orange {
	@extend .Initials;

	background-color: $orange--100;
}

.Initials--green {
	@extend .Initials;

	background-color: $green--100;
}

.Initials--gray {
	@extend .Initials;

	background-color: $gray--100;
}

.Initials--yellow {
	@extend .Initials;

	background-color: $yellow--100;
}

.Initials--blue {
	@extend .Initials;

	background-color: $blue--100;
}

.Initials--purple {
	@extend .Initials;

	background-color: $purple--100;
}

.Initials--empty {
	@extend .Initials;

	background-color: $white;
	// Personalized dotted border. Native dotted border doesn't look very nice.
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='50' ry='50' stroke='%23AFAFAFFF' stroke-width='1' stroke-dasharray='2%2c 2' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
}
