@import '~styleguide/variables';

.UserPageLoading {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 600px;
}

.UserPageSuccess {
	&__tabsNav{
		margin-top: 12px;
	}

}
