@import '~styleguide/variables';

.Display {
  margin: 0;
  font-weight: bold;

  &--large {
    font-size: 96px;
    line-height: 112px;
  }

  &--medium {
    font-size: 52px;
    line-height: 64px;
  }

  &--small {
    font-size: 44px;
    line-height: 52px;
  }

  &--x-small {
    font-size: 36px;
    line-height: 44px;
  }

  &--primary {
    color: $text__color;
  }

  &--secondary {
    color: $text__color--secondary;
  }

  &--tertiary {
    color: $text__color--tertiary;
  }

  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--justified {
    text-align: justify;
  }
}
