@import '~styleguide/variables';

.Dot {
  &::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: $gray--400;
  }

  &--positive {
    @extend .Dot;

    &::before {
      background-color: $green--400;
    }
  }

  &--negative {
    @extend .Dot;

    &::before {
      background-color: $red--400;
    }
  }
}
