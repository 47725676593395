@import '~styleguide/variables';

.AptoCard {
	position: relative;
	max-width: 340px;
	backface-visibility: hidden;
	line-height: 0;
}

.AptoCard__background {
	width: 100%;
}

.AptoCard__logo {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 14mm;
	height: 11mm;
	background: transparent;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.AptoCard__network {
	position: absolute;
	right: 12px;
	bottom: 10px;
}

.AptoCard__details {
	position: absolute;
	bottom: 20px;
	left: 20px;
}

.AptoCard__text {
	color: $white;

	&--white {
		color: $gray--900;
	}
}
