@import '~styleguide/variables';

.ErrorPage {
	width: 100%;
	max-width: 600px;
	margin: 36px auto;
	padding: 12px;
}

.ErrorPage__circleIcon {
	width: 80px;
	height: 80px;
	margin-bottom: 40px;
}
