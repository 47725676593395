@import '~styleguide/variables';

.Layout {
	display: grid;
	grid-template-areas:
		'header header'
		'aside main';
	grid-template-columns: 68px 1fr;
	grid-template-rows: 65px 1fr;
	width: 100vw;
	height: 100vh;
	overflow: hidden;

	@media all and (min-width: $breakpoint--lg) {
		grid-template-columns: 348px 1fr;
	}

	.Wrapper {
		grid-area: main;
		height: 100vh;
		overflow: hidden;

		.ContentWrapper {
			max-height: calc(100% - 64px); //100% - header height
			overflow: auto;

			.Content {
				padding: 24px 0px;
				@media all and (min-width: $breakpoint--md) {
					padding: 24px 20px;
				}
			}
		}
	}
}
