.NotificationPopup {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}

.NotificationPopup__footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	margin-top: 40px;
}
