@import '~styleguide/variables';

.ActivateAccount {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;

	&__column {
		max-width: 500px;

		@media screen and (min-width: 900) {
			&:first-child {
				margin-right: 120px;
			}
		}
	}

	&__subtitle {
		display: inline-block;
		padding: 14px 0;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 32px;
	}

	&__itemsGroup:first-child {
		margin-right: 36px;
	}

	&__cta {
		width: 100%;
	}
}
