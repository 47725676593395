@import '~styleguide/variables';

.Text {
  display: block;
  margin: 0;
  color: $gray--900;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;

  &.h1 {
    font-size: 52px;
    font-weight: 600;
    line-height: 64px;

    @media screen and (max-width: $breakpoint--lg - 1) {
      font-size: 36px;
      line-height: 44px;
    }
  }

  &.h4 {
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;

    @media screen and (max-width: $breakpoint--lg - 1) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  &.h5 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  &.h6 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  &.h7 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  &.medium {
    font-size: 16px;
    line-height: 24px;
  }

  &.small {
    font-size: 14px;
    line-height: 16px;
  }

  &.footnote {
    font-size: 12px;
    line-height: 20px;
  }

  &.isMuted {
    color: $gray--500;
  }

  &.isBody {
    color: $gray--600;
  }

  &.isNegative {
    color: $color-negative;
  }

  &.isBlack {
    color: $black;
  }

  &.isPositive {
    color: $text__color--positive;
  }

  &.isPrimary {
    color: $color-primary;
  }

  &.isCenter {
    text-align: center;
  }

  &.isBlock {
    display: block;
  }

  &.isInline {
    display: inline;
  }
}
