@import '~styleguide/variables';
@import '~styleguide/utilities/mixins';

.Spinner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.Sync2FA {
	max-width: 664px;
	margin: 50px auto 0;

	@media screen and (min-width: $breakpoint--lg) {
		margin-top: 100px;
	}
}

.Sync2FA__grid {
	margin-top: 40px;
}

.Sync2FA__block {
	padding-bottom: 24px;

	&:not(:first-child) {
		padding-top: 24px;
		border-top: 1px solid $gray--100;
	}

	button {
		@include focus();
	}
}

.Sync2FA__accent {
	color: $color-primary;
}
