@import '~styleguide/variables';

.AptoCardSection {
	display: flex;
	flex-direction: column;

	@media screen and (min-width: $breakpoint--lg) {
		flex-direction: row-reverse;
		align-items: flex-start;
		justify-content: flex-end;
	}

	&__container {
		&--first {
			margin-bottom: 16px;
			margin-left: 0;

			@media screen and (min-width: $breakpoint--lg) {
				flex-shrink: 0;
				margin-bottom: 0;
				margin-left: 40px;
			}
		}

		&--second {
			display: flex;
		}
	}

	&__card {
		overflow: hidden;
		border-radius: 16px;
		box-shadow: $shadow;
	}
}
