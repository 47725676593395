@import '~styleguide/variables';

.CreateAccount__gridItem {
	@media screen and (min-width: $breakpoint--lg) {
		grid-column-start: 6;
	}
}

.CreateAccount {
	width: 100%;
	max-width: 500px;
	margin: 12px auto 0;
}

.CreateAccount__Group {
	@media all and (min-width: $breakpoint--md) {
		display: flex;
		justify-content: space-around;
	}

	div:first-child {
		margin-bottom: 12px;

		@media all and (min-width: $breakpoint--md) {
			margin-right: 12px;
		}
	}
}

.CreateAccount__terms {
	margin: 0 0 24px;
}

.CreateAccount__inlineCta {
	font-weight: 500;
}
