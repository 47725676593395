@import '~styleguide/variables';

@for $i from 1 through 16 {
  .Item--#{$i} {
    grid-column-end: span #{$i};
  }
}

@media screen and (min-width: $breakpoint--lg) {
  @for $i from 1 through 16 {
    .Item-lg--#{$i} {
      grid-column-end: span #{$i};
    }
  }
}
