@import '~styleguide/variables';

.AchDetails__item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 56px;
	border-bottom: 1px solid $gray--100;

	&:first-child {
		border-top: 1px solid $gray--100;
	}
}
