.PendingApplicationPage {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 468px;
	margin: 88px auto;
	padding: 12px;
	text-align: center;
}
