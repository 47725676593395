@import '~styleguide/variables';

.CircleIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 5px solid $gray--50;
  border-radius: 50%;
}
