@import '~styleguide/variables';

.OverviewFundingAccount {
	display: flex;
	box-sizing: border-box;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 12px 30px 24px;
	border-radius: 12px;

	& > * {
		margin-top: 12px;
	}

	&__sandboxNote {
		max-width: 384px;
	}
}

.OverviewFundingAccount--production {
	border: 1px solid $gray--200;
}

.OverviewFundingAccount--sandbox {
	background: $yellow--50;
}

.OverviewFundingAccountHeading {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	&__label {
		flex: 0 0 auto;
	}

	&__info {
		width: 16px;
		height: 16px;
		margin: 0 0 8px 4px;
		background-image: url('/icons/info.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		cursor: pointer;
	}
}
