@import '~styleguide/variables';

.CredentialItem {
	padding: 0 20px;

	&:hover,
	&:focus-within {
		border-radius: 16px;
		background-color: $gray--50;
	}

	&--isExpanded {
		margin-bottom: 12px;
		border-radius: 16px;
		box-shadow: $shadow;

		&:hover,
		&:focus-within {
			background: $white;
		}
	}

	&__header {
		width: 100%;
		padding: 14px 0;
		border: none;
		border-bottom: 1px solid $gray--100;
		background: none;
		cursor: pointer;
	}

	&:not(&--isExpanded) .CredentialItem__header:focus {
		outline: none;
	}

	&__grid {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	&__body {
		padding-top: 24px;
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 18px 0 20px;
	}

	&__longText {
		line-height: 20px;
		word-break: break-all;
	}
}
