@import '~styleguide/variables';

.CredentialsSectionSuccess__cta {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 40px 18px 77px;
	padding: 20px 24px;
	border-radius: 8px;
	background-color: $gray--50;
}
