@import '~styleguide/variables';

/* === SPACING ===
* This generates spacing utility classes, ie:
*
* .u-mt__20--md --> margin-top: 20px in medium size and up devices
* .u-pr__24--xs --> padding-right: 24px
*/
$whitespaces: (0, 8, 12, 20, 24, 36, 60); //in pixels
$sides: (top, bottom, left, right);
$breakpoints: (
  'xs': $breakpoint--xs,
  'sm': $breakpoint--sm,
  'md': $breakpoint--md,
  'lg': $breakpoint--lg,
  'xl': $breakpoint--xl,
);

@each $breakpoint, $value in $breakpoints {
  @media screen and (min-width: #{$value}) {
    @each $space in $whitespaces {
      @each $side in $sides {
        .u-m#{str-slice($side, 0, 1)}__#{$space}--#{$breakpoint} {
          margin-#{$side}: #{$space}px !important;
        }
      }
    }
  }
}
