@import '~styleguide/variables';

.AmountInput {
	display: flex;
	flex-direction: column;
	margin: 20px auto;
}

.AmountInput__amount {
	display: flex;
	height: 64px;
}

.AmountInput__input,
.AmountInput__currency {
	font-size: 52px;
	font-weight: bold;
	line-height: 64px;
}

.AmountInput__input {
	width: 100%;
	height: 64px;
	border: none;
	outline: none;
	caret-color: $blue--400;

	&::placeholder {
		color: $gray--200;
	}

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
