@import '~styleguide/variables';

.ReviewStep {
	width: 100%;
	margin-bottom: 24px;
	padding: 28px 24px 40px;

	@media all and (min-width: $responsive-tablet) {
		border-radius: 16px;
		box-shadow: $shadow;
	}
}

.ReviewStep__text {
	display: inline-block;
	margin: 14px 0;
}

.ReviewStep__cta {
	width: 100%;
	margin-top: 86px;
}

.ReviewStep__exit {
	margin-top: 28px;
	text-align: center;
}

.DetailsBox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 64px;
	border-bottom: 1px solid $gray--100;
}

.DetailsBox__card {
	width: 100%;
	text-align: right;

	& > div {
		justify-content: flex-end;
	}
}
