@import '~styleguide/variables';
@import '~styleguide/utilities/mixins';

.Webhook {
	padding: 0 20px;

	&:hover,
	&:focus-within {
		border-radius: 16px;
		background-color: $gray--50;
	}

	&--isExpanded {
		margin-bottom: 12px;
		padding: 0 20px;
		border-radius: 16px;
		background: white;
		box-shadow: $shadow;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 14px 0;
		border: none;
		border-bottom: 1px solid $gray--100;
		outline: none;
		background: none;
		text-align: left;
		cursor: pointer;
	}

	&--isExpanded .Webhook__header {
		@include focus();
	}

	&__grid {
		width: 100%;
	}

	&__body {
		padding-top: 24px;
		border-top: 1px solid $gray--100;
	}

	&__url {
		line-height: 20px;
		word-break: break-all;
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 18px 0 20px;
	}
}
