.Form {
	&__row {
		display: grid;
		grid-gap: 12px;
		grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
		margin-bottom: 16px;
	}

	&__userConsent {
		margin-top: 24px;
		font-size: 14px;
		line-height: 20px;
	}
}
