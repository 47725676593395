@import '~styleguide/variables';

.Heading {
  margin: 0;
  font-weight: 500;

  &--xx-large {
    font-size: 40px;
    line-height: 52px;
  }

  &--x-large {
    font-size: 36px;
    line-height: 44px;
  }

  &--large {
    font-size: 32px;
    line-height: 40px;
  }

  &--medium {
    font-size: 28px;
    line-height: 36px;
  }

  &--small {
    font-size: 24px;
    line-height: 32px;
  }

  &--x-small {
    font-size: 20px;
    line-height: 28px;
  }

  &--primary {
    color: $text__color;
  }

  &--secondary {
    color: $text__color--secondary;
  }

  &--tertiary {
    color: $text__color--tertiary;
  }

  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--justified {
    text-align: justify;
  }
}
