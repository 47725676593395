@import '~styleguide/variables';

$cell__padding: 18px;

.Table {
  width: 100%;
  border-collapse: collapse;

  tbody tr {
    padding: 0 18px;
    cursor: pointer;
  }

  .cell {
    border-bottom: 1px solid $gray--100;
    color: $black;
    font-style: normal;
    font-weight: 400;
    text-align: left;
  }

  th {
    @extend .cell;

    padding: 18px 0;
    font-size: 14px;
    line-height: 20px;

    &:first-of-type,
    &:last-of-type {
      width: 20px;
      border-bottom-color: transparent;
    }
  }

  td {
    @extend .cell;

    height: 56px;
    font-size: 14px;
    line-height: 20px;

    &:first-of-type {
      width: 20px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-bottom-color: transparent;
    }

    &:last-of-type {
      width: 20px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-bottom-color: transparent;
    }
  }

  tr:hover td {
    background-color: $gray--50;
  }
}

.EmptyTable {
  display: flex;
  align-items: center;
  justify-content: center;
}
