@import '~styleguide/variables';

.ModalAddTransactions {
	margin-top: 30px;
}

.ModalAddTransactions__link {
	display: inline-block;
	margin-bottom: 24px;
}

.ModalAddTransactions__terminal {
	margin: 0 0 40px;
	padding: 24px 24px 16px;
	border-radius: 8px;
	background-color: $black;
	font-family: $font-family;
}

.ModalAddTransactions__code {
	margin: 0;
	color: $white;
}

.ModalAddTransactions__highlight {
	color: $color-primary;
	font-weight: bold;
}

.ModalAddTransactions__footer {
	text-align: right;
}
