.CreateWebhook {
	max-width: 600px;

	&__FieldSet {
		margin-top: 28px;
		padding: 0;
		border: none;
	}

	&__ButtonArea {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 28px;
		padding: 0;
	}

	&__CheckBox {
		display: flex;
		justify-content: space-between;
		padding: 12px 0;

		label {
			font-size: 14px;
			line-height: 20px;
		}
	}
}
