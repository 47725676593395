@import '~styleguide/variables';

.CardSelector {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 8px 0;
	padding: 21px 24px;
	border: 1px solid $gray--200;
	border-radius: 8px;
	cursor: pointer;

	&--active,
	:focus {
		@extend .CardSelector;

		border: 1px solid $color-primary;
	}

	&__input {
		position: absolute;
		width: 0;
		height: 0;
		opacity: 0;
	}
}
