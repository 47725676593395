@import '~styleguide/variables';

.Header {
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-start;
	justify-content: space-between;
	padding: 24px 0 18px;
	border-bottom: 1px solid $gray--100;

	@media screen and (min-width: $breakpoint--md) {
		flex-direction: row;
		align-items: center;
	}

	&__left {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__initials {
		width: 64px;
		height: 64px;
		margin-right: 14px;
		font-size: 16px !important;
		line-height: 20px !important;
	}

	&__loadFunds{
    margin: 0 12px 30px 0;

		@media screen and (min-width: $breakpoint--md) {
			margin-bottom: 0;;
		}
	}
}
