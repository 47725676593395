@import '~styleguide/variables';
@import '~styleguide/utilities/mixins';
@import '~styleguide/utilities/functions';

.CompanyToogle {
	position: relative;
	padding: 4px 44px 4px 4px;
	border-radius: 12px;
	background-repeat: no-repeat;
	background-position: calc(100% - 24px) center;
	@include chevron-right(encode-color($black));

	&:hover,
	&:focus {
		background-color: $gray--50;
	}

	@include focus();
}
