@import '~styleguide/variables';
@import '~styleguide/utilities/mixins';
.AccountsSection {
	border-bottom: 1px solid $gray--100;
}

.AccountsSection__header {
	margin-bottom: 16px;

	span {
		display: inline-block;
	}
}

.AccountsSectionHeading {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.AccountsSectionHeading__info {
	width: 16px;
	height: 16px;
	margin: 0 0 12px 4px;
	background-image: url('/icons/info.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	cursor: pointer;
}

.FundingAccount__info {
	margin-bottom: 20px;
}

.FundingAccount__row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 56px;
	padding: 10px 0;
	border: none;
	border-top: 1px solid $gray--100;

	&:last-of-type {
		border-bottom: 1px solid $gray--100;
	}

	&--achdetails {
		position: relative;
		background: none;
		cursor: pointer;

		&::after {
			content: '';
			position: absolute;
			top: calc(50% - 12px);
			right: 8px;
			width: 24px;
			height: 24px;
			background-image: url('/icons/chevron-right.svg');
		}

		@include focus();
	}
}
