@import '~styleguide/variables';

.ModalDeactivateAccount {
	padding-top: 30px;
}

.ModalDeactivateAccount__footer {
	text-align: right;
}

.ModalDeactivateAccount__cta {
	margin-left: 12px;
	border-color: $red--400;
	background: $red--400;
}
