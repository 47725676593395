@import '~styleguide/variables';

.CardFlipper {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: min-content;
  perspective: 1000px;

  &__content {
    position: relative;
    grid-area: 1 / 1;
    width: 100%;
    height: 100%;
    transform: rotateY(360deg);
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.09, 0.7, 0.3, 1.15);

    &--flipped {
      @extend .CardFlipper__content;

      transform: rotateY(180deg);
    }
  }

  &__side {
    grid-area: 1 / 1;
    backface-visibility: hidden;

    &--front {
      @extend .CardFlipper__side;

      position: absolute;
      top: 0;
      left: 0;
    }

    &--back {
      @extend .CardFlipper__side;

      transform: rotateY(180deg);
    }
  }

  &__button {
    margin-top: 24px;
  }

  &__icon {
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      width: 52px;
      height: 52px;
      margin-right: 14px;
      border-radius: 50%;
      background-color: $white;
      background-image: url('./arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0 0 40px rgba($black, 0.12);
    }
  }
}
