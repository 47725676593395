@import '~styleguide/variables';

.Paragraph {
  margin: 0;

  &--large {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.03em;
  }

  &--medium {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
  }

  &--small {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;
  }

  &--x-small {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.005em;
  }

  &--primary {
    color: $text__color;
  }

  &--secondary {
    color: $text__color--secondary;
  }

  &--tertiary {
    color: $text__color--tertiary;
  }

  &--disabled {
    color: $text__color--disabled;
  }

  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--justified {
    text-align: justify;
  }
}
