@import '~styleguide/variables';

.ModalAccountActivation {
	display: flex;
	flex-direction: column;
	margin-top: 24px;

	&__footer {
		display: flex;
		align-content: center;
		justify-content: flex-end;
		width: 100%;
		padding-top: 16px;
	}

	&__cta {
		margin-left: 12px;
	}
}
