@import '~styleguide/variables';
@import '~styleguide/utilities/functions';

@mixin profile-avatar($bgColor, $avatarColor) {
	background-image: url('data:image/svg+xml,%3Csvg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="12" cy="12" fill="#{$bgColor}" r="12"/%3E%3Cg fill="#{$avatarColor}"%3E%3Cpath d="m12.1204 11.8887c1.933 0 3.5-1.567 3.5-3.50003 0-1.93299-1.567-3.5-3.5-3.5s-3.50004 1.56701-3.50004 3.5c0 1.93303 1.56704 3.50003 3.50004 3.50003z"/%3E%3Cpath d="m6.24976 17.3887c0-2.4853 2.01471-4.5 4.50004-4.5h2.5c2.4852 0 4.5 2.0147 4.5 4.5v1.5h-11.50004z"/%3E%3C/g%3E%3C/svg%3E');
}

.Header {
	display: flex;
	grid-area: header;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 64px;
	padding: 0 12px;
	border-bottom: 1px solid $gray--100;

	@media all and (min-width: $breakpoint--lg) {
		padding-right: 36px;
		padding-left: 36px;
	}

	&__company {
		max-width: 125px;

		@media all and (min-width: $breakpoint--sm) {
			max-width: 296px;
		}
	}

	&__links {
		& > a {
			display: none;

			@media all and (min-width: $breakpoint--md) {
				display: inline-block;
			}
		}
	}

	&__pill {
		margin-left: 12px;
		padding-right: 11px;
		padding-left: 11px;
	}
}

.User {
	margin-left: 12px;
	padding-right: 38px;
	padding-left: 11px;
	border-color: $gray--100;
	background-repeat: no-repeat;
	background-position: calc(100% - 6px) center;
	box-shadow: 0 1px 4px rgba($black, 0.08);
	composes: Header__pill;

	&--orange {
		@include profile-avatar(encode-color($orange--100), encode-color($orange--300));
	}
	&--green {
		@include profile-avatar(encode-color($green--100), encode-color($green--300));
	}
	&--yellow {
		@include profile-avatar(encode-color($yellow--100), encode-color($yellow--300));
	}
	&--gray {
		@include profile-avatar(encode-color($gray--100), encode-color($gray--300));
	}
	&--blue {
		@include profile-avatar(encode-color($blue--100), encode-color($blue--300));
	}
	&--purple {
		@include profile-avatar(encode-color($purple--100), encode-color($purple--300));
	}
}
