@import '~styleguide/variables';

.Owner {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	border-top: 1px solid $gray--100;
	cursor: pointer;

	&__name {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__initials {
		width: 36px;
		height: 36px;
		margin-right: 16px;
	}
}
