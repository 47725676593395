.PaymentMethodSelect {
	&__placeholder {
		width: 100%;
		padding: 24px 0;
		padding-left: 20px;
		border: none;
		outline: none;
		background: none;
		text-align: left;
		cursor: pointer;
	}
}
