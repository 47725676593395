@import '~styleguide/variables';

.CountryInput {
  position: absolute;
  top: 0;
  width: 94px;
  height: 52px;

  &__label {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 7px;
    left: 11px;
    width: 70px;
    height: 34px;
    background: transparent;
    background-image: url('../../arrow.svg');
    background-repeat: no-repeat;
    background-position: left 55px top 60%;
    font-size: 34px;
    pointer-events: none;
  }

  &__input {
    position: absolute;
    top: 7px;
    left: 11px;
    width: 70px;
    height: 34px;
    border: none;
    outline: 0;
    opacity: 0;
  }
}

.CountryInput.is-disabled {
  .CountryInput__input {
    border-color: $gray--50;
    color: $text__color--disabled;
  }

  .CountryInput__label {
    background-color: #fafafa; // add to _variables.scss?
    color: #fafafa;
  }
}
