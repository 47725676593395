@import '~styleguide/variables';

.LegalItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-left: 20px;
	padding: 14px 0;
	border-bottom: 1px solid $gray--100;
}
