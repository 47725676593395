@import '~styleguide/variables';

.Details {
	padding: 0 20px;

	&:hover,
	&:focus-within {
		border-radius: 16px;
		background-color: $gray--50;
	}

	&__Summary {
		display: block;
		width: 100%;
		min-height: 56px;
		padding: 10px 0;
		border: none;
		border-top: 1px solid $gray--100;
		background: transparent;
		text-align: initial;
		cursor: pointer;

		&:active {
			outline: none;
		}
	}

	&:last-of-type {
		.Details__Summary {
			border-bottom: 1px solid $gray--100;
		}
	}
}

.Details--isExpanded ~ .Details__Summary {
	border-top: none;
}

.Details--isExpanded {
	margin-bottom: 18px;
	padding: 0 18px;
	border-radius: 16px;
	background: white;
	box-shadow: $shadow;

	&:hover,
	&:focus-within {
		background: $white;
	}

	.Details__Summary {
		border-top: none;
		border-bottom: 1px solid $gray--100;
		padding-left: 0;
		padding-right: 0;

		&:last-child {
			border-bottom: none;
		}
	}

	.Details__Content {
		padding: 24px 0 16px;
	}
}
