@import '~styleguide/variables';

.Overview {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(
		100vh - (71px + 3.5rem)
	); // Header (71px) + Content padding (3rem) + 0.5rem padding to align with environment select

	padding: 0 16px 0 20px;
}

.Overview__grid {
	grid-gap: 32px;
}

.Overview__cta {
	margin-right: 16px;
	margin-bottom: 12px;
}

.GetStarted {
	&__gridItem {
		@media screen and (min-width: $breakpoint--lg) {
			grid-column-start: 8;
		}
	}

	&__list {
		margin: 0;
		margin-bottom: 8px;
		padding: 0 0 0 20px;
		font-size: 14px;
		line-height: 20px;
	}

	&__highlight {
		font-weight: bold;
		font-style: italic;
	}
}
