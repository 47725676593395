@import '~styleguide/variables';

.SignedPayloadsKey {
	width: 100%;
	padding: 10px 0;

	&__grid {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		min-height: 43px; // so the row is 64px height
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 16px;
	}

	&__ellipsis {
		display: inline-block;
		max-width: 160px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__longText {
		line-height: 20px;
		word-break: break-all;
	}

	&__publicKey {
		@extend .SignedPayloadsKey__longText;

		white-space: pre-line;
	}
}
