@import '~styleguide/variables';

@mixin drawer-icon($image-url, $active-image-url) {
	background-image: url($image-url);

	&.is-active,
	&:hover,
	&:focus {
		background-image: url($active-image-url);
	}
}

.Drawer {
	display: flex;
	grid-area: aside;
	flex-direction: column;
	height: calc(100% - 65px);
	box-shadow: 1px 0 0 $gray--100;

	.Drawer__nav {
		display: flex;
		flex-direction: column;
		padding: 24px 8px;

		@media all and (min-width: $breakpoint--lg) {
			padding-right: 16px;
			padding-left: 16px;
		}

		.Link {
			display: flex;
			align-items: center;
			width: 52px;
			height: 48px;
			margin-bottom: 4px;
			border-radius: 12px;
			background-repeat: no-repeat;
			background-position: 12px center;
			text-decoration: none;

			@media all and (min-width: $breakpoint--lg) {
				width: auto;
				padding: 0 50px 0 54px;
				background-position: 20px center;
			}

			&.is-active {
				background-color: $gray--50;
				color: $blue--500;
			}

			&:hover,
			&:focus {
				color: $blue--500;
			}

			&.isDisabled {
				color: gray;
				pointer-events: none;
			}

			img {
				max-width: 18px;
				max-height: 18px;
				margin-right: 18px;
			}

			&--home {
				@include drawer-icon('./icons/home.svg', './icons/home-active.svg');
			}

			&--users {
				@include drawer-icon('./icons/users.svg', './icons/users-active.svg');
			}

			&--cards {
				@include drawer-icon('./icons/cards.svg', './icons/cards-active.svg');
			}

			&--transactions {
				@include drawer-icon('./icons/transactions.svg', './icons/transactions-active.svg');
			}

			&--developers {
				@include drawer-icon('./icons/developers.svg', './icons/developers-active.svg');
			}

			&--billing {
				@include drawer-icon('./icons/billing.svg', './icons/billing-active.svg');
			}

			&--settings {
				@include drawer-icon('./icons/settings.svg', './icons/settings-active.svg');
			}

			span {
				display: none;

				@media all and (min-width: $breakpoint--lg) {
					display: inline;
				}
			}
		}
	}

	.Drawer__actions {
		flex: 1;
	}

	.Banner {
		display: none;
		flex-wrap: wrap;
		align-items: center;
		height: 128px;
		margin: 12px 48px;
		padding: 24px 20px 20px;
		border-radius: 12px;
		background-color: $yellow--50;

		@media all and (min-width: $breakpoint--lg) {
			display: flex;
		}

		&__icon {
			margin: 0 0 16px 6px;
		}

		&__text {
			color: $yellow--600;
			font-size: 14px;
			line-height: 20px;
		}
	}

	.Drawer__bot {
		display: none;
		padding: 0 48px 32px;

		@media all and (min-width: $breakpoint--lg) {
			display: block;
		}
	}
}
