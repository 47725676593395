@import '~styleguide/variables';

.LogoPreview {
	position: relative;
	max-width: 150px;
	margin-top: 24px;
	border: 1px solid $gray--200;

	img {
		display: block;
		width: 100%;
	}
}

.LogoPreview__overlay {
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	opacity: 0;
	background-color: rgba($black, 0.8);
}

.LogoPreview:hover .LogoPreview__overlay,
.LogoPreview:focus-within .LogoPreview__overlay {
	opacity: 1;
}

.LogoPreview__button {
	border-color: transparent;
	background: transparent;
	color: $white;

	&:focus,
	&:hover {
		background-color: inherit;
		color: $white;
	}
}
