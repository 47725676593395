@import '~styleguide/variables';

.ModalDeleteMember {
	display: flex;
	flex-direction: column;
	min-height: 136px;
	margin-top: 30px;

	&__body {
		margin-bottom: 24px;
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 16px;
		text-align: right;
	}

	&__cta {
		margin-left: 16px;
		border-color: $red--400;
		background: $red--400;

		&:disabled {
			border-color: $red--200;
			background-color: $red--200;
		}
	}
}
