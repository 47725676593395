@import '~styleguide/variables';
@import '~styleguide/utilities/functions';
@import '~styleguide/utilities/mixins';

@mixin chevron-down($color) {
	background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.2336 7.7418L10.0002 10.9751L6.76689 7.7418C6.44189 7.4168 5.91689 7.4168 5.59189 7.7418C5.26689 8.0668 5.26689 8.5918 5.59189 8.9168L9.41689 12.7418C9.74189 13.0668 10.2669 13.0668 10.5919 12.7418L14.4169 8.9168C14.7419 8.5918 14.7419 8.0668 14.4169 7.7418C14.0919 7.42513 13.5586 7.4168 13.2336 7.7418Z" fill="#{$color}"/></svg>');
}

.TeamRoleSelect {
	&__toggle {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: flex-start;
		padding: 0;
		border: none;
		background: none;
		text-transform: capitalize;
		cursor: pointer;

		&::after {
			content: '';
			display: inline-block;
			width: 20px;
			height: 20px;
			margin-left: 4px;
			background-repeat: no-repeat;
			pointer-events: none;
			@include chevron-down(encode-color($black));
		}

		@include focus();
	}

	&__dropdown {
		display: flex;
		position: absolute;
		z-index: 1;
		flex-direction: column;
		width: 170px;
		margin-top: 16px;
		overflow: hidden;
		border-radius: 8px;
		background-color: $white;
		box-shadow: $shadow;
		button:not(.TeamRoleSelect__item--disabled):hover,
		button:not(.TeamRoleSelect__item--disabled):focus {
			background-color: $gray--100;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
	}

	&__item {
		flex-shrink: 0;
		width: 100%;
		padding: 12px 16px;
		border: none;
		outline: none;
		background: none;
		text-align: left;
		text-transform: capitalize;
		cursor: pointer;

		&--disabled {
			cursor: not-allowed;
		}
	}
}
