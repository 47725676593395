.PaymentsHeader {
	display: flex;
	justify-content: flex-start;
	margin-top: 34px;
	margin-bottom: 10px;

	> * {
		margin-left: 12px;
	}
}

.PaymentMethodItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.PaymentMethodItem__cardInfo {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	max-width: calc(100% - 100px);
}

.PaymentMethodItem__card {
	width: 48px;
	height: 48px;
	margin-right: 16px;
	background-repeat: no-repeat;
	background-size: contain;

	&--amex {
		composes: PaymentMethodItem__card;
		background-image: url('images/amex.svg');
	}

	&--discover {
		composes: PaymentMethodItem__card;
		background-image: url('images/discover.svg');
	}

	&--mastercard {
		composes: PaymentMethodItem__card;
		background-image: url('images/mastercard.svg');
	}

	&--visa {
		composes: PaymentMethodItem__card;
		background-image: url('images/visa.svg');
	}
}
