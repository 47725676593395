@import '~styleguide/variables';

.TwoFactorAuth__gridItem {
	@media screen and (min-width: $breakpoint--lg) {
		grid-column-start: 6;
	}
}

.TwoFactorAuth {
	width: 100%;
	max-width: 500px;
	margin: 12px auto 0;
}

.TwoFactorAuth__form {
	display: flex;
	margin-top: 24px;
	padding-top: 24px;
	border-top: 1px solid $gray--200;

	& > div {
		margin-right: 12px;
	}
}
