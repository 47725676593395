@import '~styleguide/variables';

.ModalAddUsers {
	margin-top: 30px;

	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		min-height: 56px;
		padding: 10px 0;
		border: none;
		border-bottom: 1px solid $gray--100;
	}

	&__links {
		*:first-child {
			margin-right: 12px;
		}
	}
}
