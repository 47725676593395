@import '~styleguide/variables';

.SectionTitle {
	display: flex;
	align-items: center;
	width: 100%;
	height: 56px;
	margin-top: 20px;
	box-shadow: inset 0 -1px 0 $gray--100;

	&--borderTop {
		box-shadow: inset 0 -1px 0 0 $gray--100, inset 0 1px 0 0 $gray--100
	}

	&--marginTopCorrection {
		@media screen and (min-width: $breakpoint--xl) {
			margin-top: -20px;
		}
	}
}
