@import '~styleguide/variables';

.VerifyPhoneNumber {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
}

.Card {
	min-width: 450px;
	margin: 0 auto;
	border-radius: 16px;
	box-shadow: $shadow;
}

.Card__body {
	padding: 1px 24px 40px;
}

.Card__footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 24px;
}
