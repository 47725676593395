@import '~styleguide/variables';

.Item {
  padding: 27px 0;
  border-top: 1px solid $gray--100;

  &--borderBottom {
    @extend .Item;
    border-bottom: 1px solid $gray--100;
  }
}
