@import '~styleguide/variables';

.TeamsListItem {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 14px 0;
	border-bottom: 1px solid $gray--100;

	&__action {
		margin-left: auto;
	}
}
