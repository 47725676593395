@import '~styleguide/variables';
@import '~styleguide/utilities/mixins';

.Business__asideItem {
	@media screen and (min-width: $breakpoint--lg) {
		grid-column-start: 10;
	}
}

.Business__item--last {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
}

.Business__aside {
	margin-top: 36px;
	padding: 40px 20px;
	border: 1px solid $gray--200;
	border-radius: 8px;
}

.buttonReset {
	padding: 0;
	border: 0;
	background: none;
	cursor: pointer;

	@include focus();
}
