@import '~styleguide/variables';

.Input {
  position: relative;
  width: 100%;
}

.Input__label {
  position: absolute;
  z-index: 1;
  top: 14px;
  left: 16px;
  transition: all 0.2s;
  border: none;
  color: $gray--500;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  pointer-events: none; // This is positioned on top of the input
}

.Input__input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  padding: 22px 16px 2px 16px;
  border: 1px solid $gray--200;
  border-radius: 8px;
  outline: none;
  color: transparent;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    border: 1px solid $black;
  }

  &:active,
  &:focus {
    border: 1px solid $color-primary;

    & + .Input__label {
      color: $color-primary;
    }
  }
}

.Input__input--text {
  composes: Input__input;

  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.Input__input--date {
  composes: Input__input;
  font-family: $font-family;
  font-size: 14px;

  &::-webkit-calendar-picker-indicator {
    position: relative;
    top: -10px;
  }
}

.Input__input--select {
  composes: Input__input;
  margin: 0;
  background-image: url('./arrow.svg');
  background-repeat: no-repeat;
  background-position: right 22px top 50%;
  appearance: none;

  &:focus {
    // Fixes a bug in Firefox displaying the dropdown in transparent color
    color: $black;
  }
}

.Input__input--textarea {
  composes: Input__input;
  display: block;
  height: auto;
  margin-top: 12px;
  padding: 24px 16px;
  font-family: $font-family;
  resize: none;
}

.Input:focus-within,
.Input.has-content {
  .Input__label {
    top: 1px; // Place it inside input border
    right: 12px; // Let space for the scrollbar on textarea
    left: 1px;
    padding: 10px 0 0 16px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 1;
  }

  .Input__input {
    color: $black;
    font-size: 16px;
  }
}

.Input.is-disabled {
  .Input__input {
    border-color: $gray--50;
    background-color: $gray--50;
    color: $text__color--disabled;
  }

  .Input__label {
    color: $text__color--disabled;
  }
}

.Input.has-error {
  .Input__input {
    border-color: $color-negative;
    font-size: 16px;
  }

  .Input__input--date {
    border-color: $color-negative;
  }

  .Input__input--select {
    border-color: $color-negative;
    background-image: unset;
  }

  .Input__textarea {
    border-color: $color-negative;
  }

  .Input__error {
    display: block;
    position: absolute;
    z-index: 1;
    top: 1px;
    right: 16px;
    padding-top: 6px;
    color: $color-negative;
    font-size: 12px;
    line-height: 20px;
  }
}
