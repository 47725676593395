@import '~styleguide/variables';
@import '~styleguide/utilities/mixins';

.Password {
  position: relative;
  width: 100%;
}

.Password__label {
  position: absolute;
  z-index: 1;
  top: 14px;
  left: 16px;
  transition: all 0.2s;
  border: none;
  color: $gray--500;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  pointer-events: none; // This is positioned on top of the input
}

.Password__input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  padding: 22px 16px 2px 16px;
  border: 1px solid $gray--200;
  border-radius: 8px;
  outline: none;
  color: transparent;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    border: 1px solid $black;
  }

  &:active,
  &:focus {
    border: 1px solid $color-primary;

    & + .Password__label {
      color: $color-primary;
    }
  }
}

.Password:focus-within,
.Password.has-content {
  .Password__label {
    top: 1px; // Place it inside input border
    right: 12px; // Let space for the scrollbar on textarea
    left: 1px;
    padding: 10px 0 0 16px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 1;
  }

  .Password__input {
    color: $black;
    font-size: 16px;
  }
}

.Password__button {
  position: absolute;
  top: 14px;
  right: 14px;
  width: 24px;
  height: 24px;
  transition: top 0.2s;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;

  @include focus();
}

.Password__button--open {
  background-image: url('./img/eye.svg');
}

.Password__button--closed {
  background-image: url('./img/eye-off.svg');
}

.Password.is-disabled {
  .Password__input {
    border-color: $gray--50;
    color: $text__color--disabled;
  }

  .Password__label {
    color: $text__color--disabled;
  }
}

.Password.has-error {
  .Password__input {
    border-color: $color-negative;
    font-size: 16px;
  }

  .Password__error {
    display: block;
    position: absolute;
    z-index: 1;
    top: 1px;
    right: 16px;
    padding-top: 6px;
    color: $color-negative;
    font-size: 12px;
    line-height: 20px;
  }

  .Password__button {
    top: 22px;
  }
}
