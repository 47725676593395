@import '~styleguide/variables';

.CompanyPanel {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	box-sizing: border-box;
	width: 348px;

	& > div {
		top: 0;
		height: 100vh;
		max-height: 100vh;
		border-radius: 0;
	}

	&__inner {
		width: 100%;
		max-height: calc(100vh - 64px);
		overflow: visible;
	}

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 24px 0;
		border-top: 1px solid $gray--100;
		border-bottom: 1px solid $gray--100;
	}

	&__modalBlock {
		padding: 30px 0;
		border-bottom: 1px solid $gray--100;
	}

	&__link {
		border-bottom: none;
	}
}
