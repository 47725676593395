@import '~styleguide/variables';

.BillingStep {
	&__container {
		max-width: 488px;
	}

	&__item {
		padding-left: 107px;
		background-repeat: no-repeat;
		background-position: left top;
		margin-bottom: 40px;

		&--interview {
			composes: BillingStep__item;
			background-image: url('./images/interview.svg');
		}

		&--program-setup {
			composes: BillingStep__item;
			background-image: url('./images/program-setup.svg');
		}

		&--launch {
			composes: BillingStep__item;
			background-image: url('./images/launch.svg');
		}
	}

	&__heading {
		margin-bottom: 4px;
		font-weight: 600;
	}

	&__cta {
		margin-top: 4px;
	}
}
