@import '~styleguide/variables';

.Profile {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 100%;
	width: 300px;
	height: 100vh;
	overflow: visible;

	& > div {
		top: 0;
		height: 100vh;
		max-height: 100vh;
		border-radius: 0;
	}
}

.Profile__header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 24px 0;
	border-top: 1px solid $gray--100;
	border-bottom: 1px solid $gray--100;
}

.Profile__initials {
	width: 80px;
	height: 80px;
	font-size: 32px !important;
	line-height: 40px !important;
}

.Profile__text {
	margin-top: 16px;
	margin-bottom: 0;
}

.Profile__body {
	padding: 40px 0;
}

.Profile__button {
	width: calc(100% + 32px);
	margin-right: -16px;
	margin-bottom: 4px;
	margin-left: -16px;
	padding: 16px 50px 16px 54px;
	border: none;
	border-radius: 12px;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: 18px center;
	color: $black;
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	text-align: left;
	cursor: pointer;

	&--logout {
		background-image: url('./icons/logout.svg');
	}

	&--profile {
		background-image: url('./icons/profile.svg');
	}

	&:hover,
	&:focus {
		outline: 0;
		background-color: $gray--50;
	}
}

.Profile-enter {
	left: 100%;
}

.Profile-enter-active {
	left: calc(100% - 300px);
	transition: left 0.4s;
}

.Profile-enter-done {
	left: calc(100% - 300px);
}

.Profile-exit {
	left: calc(100% - 300px);
}

.Profile-exit-active {
	left: 100%;
	transition: left 0.4s;
}
