@import '~styleguide/variables';

.LastFour {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	max-width: 100%;
}

.LastFour__card {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	&--small {
		width: 36px;
		height: 24px;
		margin-right: 12px;
	}

	&--medium {
		width: 48px;
		height: 32px;
		margin-right: 16px;
	}

	&--large {
		width: 64px;
		height: 42px;
		margin-right: 16px;
	}

	&--amex {
		background-image: url('images/amex.svg');
		composes: LastFour__card;
	}

	&--discover {
		background-image: url('images/discover.svg');
		composes: LastFour__card;
	}

	&--mastercard {
		background-image: url('images/mastercard.svg');
		composes: LastFour__card;
	}

	&--other {
		background-image: url('images/placeholder.svg');
		composes: LastFour__card;
	}

	&--visa {
		background-image: url('images/visa.svg');
		composes: LastFour__card;
	}

	&--mastercard--black {
		background-image: url('images/mastercard-black.svg');
		composes: LastFour__card;
	}

	&--mastercard--blue {
		background-image: url('images/mastercard-blue.svg');
		composes: LastFour__card;
	}

	&--mastercard--coral {
		background-image: url('images/mastercard-coral.svg');
		composes: LastFour__card;
	}

	&--mastercard--green {
		background-image: url('images/mastercard-green.svg');
		composes: LastFour__card;
	}

	&--mastercard--grey {
		background-image: url('images/mastercard-grey.svg');
		composes: LastFour__card;
	}

	&--mastercard--indigo {
		background-image: url('images/mastercard-indigo.svg');
		composes: LastFour__card;
	}

	&--mastercard--white {
		background-image: url('images/mastercard-white.svg');
		composes: LastFour__card;
	}

	&--visa--black {
		background-image: url('images/visa-black.svg');
		composes: LastFour__card;
	}

	&--visa--blue {
		background-image: url('images/visa-blue.svg');
		composes: LastFour__card;
	}

	&--visa--coral {
		background-image: url('images/visa-coral.svg');
		composes: LastFour__card;
	}

	&--visa--green {
		background-image: url('images/visa-green.svg');
		composes: LastFour__card;
	}

	&--visa--grey {
		background-image: url('images/visa-grey.svg');
		composes: LastFour__card;
	}

	&--visa--indigo {
		background-image: url('images/visa-indigo.svg');
		composes: LastFour__card;
	}

	&--visa--white {
		background-image: url('images/visa-white.svg');
		composes: LastFour__card;
	}

	&--placeholder {
		background-image: url('images/placeholder.svg');
		composes: LastFour__card;
	}
}

.LastFour__data {
	max-width: calc(100% - 64px);
}

.LastFour__text {
	color: $black;
	white-space: nowrap;

	&--white {
		composes: LastFour__text;
		color: $white;
	}
}

.LastFour__description {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
