@import '~styleguide/variables';

.LoadFundsSidebar {
	display: flex;
	flex-direction: column;
	height: 36px;
	border-left: 1px solid $gray--400;

	&__link {
		position: relative;
		bottom: 14px;
		margin-bottom: 32px;
		padding-left: 24px;
		color: $gray--400;
		font-size: 14px;
		line-height: 16px;
		text-decoration: none;
		pointer-events: none;

		&::before {
			content: '';
			position: absolute;
			left: -5px;
			width: 10px;
			height: 10px;
			margin: 3px auto;
			border-radius: 50%;
			background-color: $gray--400;
		}

		&--isActive {
			color: $color-primary;
			font-weight: 600;
			pointer-events: initial;

			&::before {
				background-color: $black;
			}
		}
	}
}
