.TutorialModal {
	display: flex;
	flex-direction: column;
	min-height: 252px;
	margin-top: 25px;
}

.TutorialModal__close {
	align-self: flex-end;
}
