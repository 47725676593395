.ComplianceStepSuccess {
	&__form {
		max-width: 468px;
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__infoIcon {
		width: 24px;
		height: 24px;
		margin-left: 4px;
		background-image: url('./images/info.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	&__radio {
		margin-top: 20px;

		label {
			margin-left: 16px;
		}
	}

	&__cta {
		margin-top: 44px;
	}
}
