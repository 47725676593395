@import '~styleguide/variables';

.MobileApiKey {
	width: 100%;
	padding: 14px 0;
	border-bottom: 1px solid $gray--100;

	&__grid {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	&__longText {
		line-height: 20px;
		word-break: break-all;
	}
}
