@import '~styleguide/variables';

.ModalAddApiKeysStep0 {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}

.ModalAddApiKeysStep0__footer {
	display: flex;
	justify-content: flex-end;
	margin-top: 40px;
	padding: 16px 0 0;
	border-top: 1px solid $gray--100;
}
