@import '~styleguide/variables';
@import '~styleguide/utilities/functions';
@import '~styleguide/utilities/mixins';

@mixin arrow-right($color) {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M7.00004 0.333374L5.82504 1.50837L10.475 6.16671H0.333374V7.83337H10.475L5.82504 12.4917L7.00004 13.6667L13.6667 7.00004L7.00004 0.333374Z" fill="#{$color}"/></svg>');
}

.Button {
  display: inline-block;
  align-items: center;
  height: 48px;
  padding: 14px 24px;
  border: none;
  border-radius: 8px;
  background: $color-primary;
  color: $white;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &:focus,
  &:hover,
  &.isDark {
    background-color: $black;
  }

  &:active {
    background-color: $gray--700;
  }

  &:disabled {
    background: $gray--50;
    color: $text__color--disabled;
    cursor: not-allowed;
  }

  &.isBlock {
    width: 100%;
  }

  &.isLink {
    height: auto;
    margin-right: -8px;
    margin-left: -8px;
    padding: 0 8px;
    border: none;
    background: none;
    color: currentColor;

    @include focus();
  }

  &.isSmall {
    height: auto;
    padding: 10px 16px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }

  &.hasArrow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
      @include arrow-right(encode-color($white));

      content: '';
      width: 14px;
      height: 14px;
      margin-left: 60px;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:disabled {
      &::after {
        @include arrow-right(encode-color($gray--400));
      }
    }
  }

  &.isLoading {
    display: block;
    position: relative;
    background-color: rgb(180, 210, 255);
    cursor: progress;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      transform: translate(-50%, -50%);
    }

    .Button__text {
      display: flex;
      visibility: hidden;
      grid-area: 1 / 1;
      opacity: 0;
    }

    &.hasArrow {
      &::after {
        background-image: initial;
      }

      .Button__text {
        &::after {
          content: '';
          grid-area: 1 / 1;
          width: 14px;
          height: 14px;
          margin-left: 60px;
        }
      }
    }
  }

  &.isSecondary {
    border: 1px solid $color-primary;
    background-color: transparent;
    color: $color-primary;

    &:focus,
    &:hover,
    &.isDark {
      border-color: $black;
      color: $black;
    }

    &:active {
      border-color: $gray--700;
      color: $gray--700;
    }

    &:disabled {
      border-color: $gray--200;
      color: $text__color--disabled;
      cursor: not-allowed;
    }

    &.hasArrow {
      display: flex;
      align-items: center;

      &::after {
        @include arrow-right(encode-color($color-primary));
      }

      &:focus,
      &:hover,
      &.isDark {
        &::after {
          @include arrow-right(encode-color($black));
        }
      }

      &:active {
        &::after {
          @include arrow-right(encode-color($gray--700));
        }
      }

      &:disabled {
        &::after {
          @include arrow-right(encode-color($gray--400));
        }
      }

      &.isLoading {
        display: grid;

        &::after {
          background-image: initial;
        }
      }
    }
  }
}
