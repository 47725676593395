@import '~styleguide/variables';

.CompanyLogo {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: $blue--100;

	&__content {
		font-family: $font-family;
		font-style: normal;
		font-weight: 600;
	}
}

.CompanyLogo--small {
	width: 36px;
	height: 36px;

	.CompanyLogo__content {
		color: $blue--300;
		font-size: 12px;
		line-height: 16px;
	}
}

.CompanyLogo--large {
	width: 80px;
	height: 80px;

	.CompanyLogo__content {
		color: $blue--700;
		font-size: 32px;
		line-height: 40px;
	}
}
