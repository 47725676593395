@import '~styleguide/variables';

.ExpiredInviteLink {
	margin: 36px 1rem;

	&__gridItem {
		@media screen and (min-width: $breakpoint--lg) {
			grid-column-start: 6;
		}
	}
	&__circleIcon {
		width: 80px;
		height: 80px;
		margin-bottom: 40px;
	}
}
