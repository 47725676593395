@import '~styleguide/variables';

.ConfirmationStep {
	width: 100%;
	border: 1px solid $gray--100;

	@media all and (min-width: $responsive-tablet) {
		border-radius: 16px;
	}
}

.Card {
	padding: 28px 24px 40px;

	&__title {
		display: inline-block;
		margin: 14px 0;
	}

	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 64px;
		border-bottom: 1px solid $gray--100;
		text-transform: capitalize;
	}

	&__cta {
		width: 100%;
		margin-top: 24px;
	}
}
