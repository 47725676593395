@import '~styleguide/variables';

.CopyText {
	display: flex;
	align-items: center;

	&__logo {
		display: flex;
		margin-left: 12px;
		border-radius: 50%;
		background-color: transparent;
		color: transparent !important;
		cursor: pointer;

		&:hover {
			background-color: $gray--200;
		}

		&:active {
			background-color: $gray--400;
		}

		img {
			width: 36px;
			height: 36px;
		}
	}
}
