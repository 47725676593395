@import '~styleguide/variables';

.WaitForEmailPage {
	max-width: 468px;
	margin: 12px auto 0;

	&__main {
		display: flex;
		flex: 1;
		align-items: top;
		justify-content: center;
		padding-bottom: 70px; // Header height
	}

	&__logo {
		margin: 36px auto 40px;
	}

	&__confirmationText {
		display: inline-flex;
		align-items: center;
		animation: fade-in 0.5s;

		span {
			margin-right: 10px;
			margin-left: 16px;
			color: $green--400;
		}
	}

	&__svgPath {
		animation: dash 0.25s linear forwards;
		animation-delay: 0.5s;
		stroke-dasharray: 14.142135620117188;
		stroke-dashoffset: 14.142135620117188;
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}
