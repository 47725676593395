@import '~styleguide/variables';

.ModalAddApiKeysStep1 {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}

.ModalAddApiKeysStep1__important {
	color: $color-negative;
}

.ModalAddApiKeysStep1__list {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.ModalAddApiKeysStep1__listTitle {
	padding: 18px 0;
	border-bottom: 1px solid $gray--100;
}

.ModalAddApiKeysStep1__listItem {
	display: flex;
	justify-content: space-between;
	padding: 18px 0;
	border-bottom: 1px solid $gray--100;
}

.ModalAddApiKeysStep1__footer {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	padding: 20px 0 4px;
}
