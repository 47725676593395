@import '~styleguide/variables';

.PhoneNumber {
  position: relative;
  width: 100%;
}

.PhoneNumber__label {
  position: absolute;
  z-index: 1;
  top: 14px;
  left: 16px;
  transition: all 0.2s;
  border: none;
  color: $gray--500;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  pointer-events: none; // This is positioned on top of the input
}

.PhoneNumber__input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  padding: 22px 16px 2px 16px;
  border: 1px solid $gray--200;
  border-radius: 8px;
  outline: none;
  color: transparent;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    border: 1px solid $black;
  }

  &:active,
  &:focus {
    border: 1px solid $color-primary;

    & + .PhoneNumber__label {
      color: $color-primary;
    }
  }
}

.PhoneNumber__input--text {
  composes: PhoneNumber__input;
}

.PhoneNumber:focus-within,
.PhoneNumber.has-content {
  .PhoneNumber__label {
    top: 1px; // Place it inside input border
    right: 12px; // Let space for the scrollbar on textarea
    left: 1px;
    padding: 10px 0 0 16px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 1;
  }

  .PhoneNumber__input {
    color: $black;
    font-size: 16px;
  }
}

.PhoneNumber.is-disabled {
  .PhoneNumber__input {
    border-color: $gray--50;
    color: $text__color--disabled;
  }

  .PhoneNumber__label {
    color: $text__color--disabled;
  }
}

.PhoneNumber.has-error {
  .PhoneNumber__input {
    border-color: $color-negative;
    font-size: 16px;
  }

  .PhoneNumber__error {
    display: block;
    position: absolute;
    z-index: 1;
    top: 1px;
    right: 16px;
    padding-top: 6px;
    color: $color-negative;
    font-size: 12px;
    line-height: 20px;
  }
}

.PhoneNumber.has-dial-code {
  .PhoneNumber__input--text {
    padding-left: 128px;
  }

  .PhoneNumber__label {
    padding-left: 95px;
  }

  .PhoneNumber__dialCode {
    position: absolute;
    top: 26px;
    left: 95px;
    opacity: 0;
  }

  &:focus-within,
  &.has-content {
    .PhoneNumber__label {
      padding-left: 95px;
    }

    .PhoneNumber__dialCode {
      opacity: 1;
    }
  }
}
