.WebhooksSectionSuccess {
	animation: fade-in 0.6s;
	animation-fill-mode: forwards;

	&__header {
		align-items: center;
		justify-content: flex-start;
		margin-top: 40px;
		display: flex;
		padding-bottom: 10px;
		border-bottom: 1px solid #eee;
	}

	&__list {
		position: relative;
		transition: all 0.2s;
	}
}
