@import '~styleguide/variables';
@import '~styleguide/utilities/mixins';

.Tooltip {
	display: inline-block;
	position: relative;
	left: 126px;
	width: 312px;
	padding: 16px;
	border-radius: 8px;
	background: $white;
	filter: drop-shadow(0 16px 48px rgba(0, 0, 0, 0.22));
	color: $black;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;

	&__arrow {
		visibility: hidden;
		position: absolute;
		width: 16px;
		height: 12px;
		background: inherit;
		filter: drop-shadow(0 16px 48px rgba(0, 0, 0, 0.22));

		&::before {
			content: '';
			visibility: visible;
			position: absolute;
			left: -126px;
			width: 16px;
			height: 12px;
			transform: rotate(45deg);
			background: inherit;
		}

		&__target {
			@include focus();
		}
	}
}

.Tooltip[data-placement^='top'] {
	top: -12px;

	& > .Tooltip__arrow {
		bottom: -6px;
	}
}

.Tooltip[data-placement^='bottom'] {
	bottom: -12px;

	& > .Tooltip__arrow {
		top: -6px;
	}
}
