.Checkbox {
	display: block;
	position: relative;
	padding-left: 30px;
	cursor: pointer;
	font-size: 14px;
	line-height: 20px;
	user-select: none;

	&__input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		&:checked ~ .Checkbox__checkbox {
			background-color: #000;

			&:after {
				display: block;
			}
		}
	}

	&__checkbox {
		position: absolute;
		top: 0;
		left: 0;
		height: 18px;
		width: 18px;
		background-color: #fff;
		border: 2px solid #000;
		border-radius: 4px;

		&::after {
			content: '';
			position: absolute;
			display: none;
			left: 4px;
			top: 1px;
			width: 4px;
			height: 8px;
			border: solid white;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}
