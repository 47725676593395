@import '~styleguide/variables';

.CardDetails {
	&__grid {
		max-width: 1100px;
	}

	&__gridItem {
		&--first {
			@media screen and (min-width: $breakpoint--lg) {
				grid-column-end: span 12;
			}

			@media screen and (min-width: $breakpoint--xl) {
				grid-column-end: span 6;
			}
		}

		&--second {
			@media screen and (min-width: $breakpoint--lg) {
				grid-column-end: span 12;
			}

			@media screen and (min-width: $breakpoint--xl) {
				grid-column-end: span 6;
				grid-column-start: 8;
			}
		}
	}

	&__cardType {
		text-transform: capitalize;
	}

	&__cardStatus {
		font-size: 14px;
    line-height: 20px;
	}
}
