/* https://create-react-app.dev/docs/adding-css-reset/ */
@import-normalize;

@font-face {
	font-family: Montreal;
	font-weight: 100;
	src: url('../public/fonts/neue_montreal/100.woff') format('woff');
}

@font-face {
	font-family: Montreal;
	font-style: italic;
	font-weight: 100;
	src: url('../public/fonts/neue_montreal/100i.woff') format('woff');
}

@font-face {
	font-family: Montreal;
	font-weight: 300;
	src: url('../public/fonts/neue_montreal/300.woff') format('woff');
}

@font-face {
	font-family: Montreal;
	font-style: italic;
	font-weight: 400;
	src: url('../public/fonts/neue_montreal/400i.woff') format('woff');
}

@font-face {
	font-family: Montreal;
	font-weight: 400;
	src: url('../public/fonts/neue_montreal/400.woff') format('woff');
}

@font-face {
	font-family: Montreal;
	font-style: italic;
	font-weight: 400;
	src: url('../public/fonts/neue_montreal/400i.woff') format('woff');
}

@font-face {
	font-family: Montreal;
	font-weight: 500;
	src: url('../public/fonts/neue_montreal/500.woff') format('woff');
}

@font-face {
	font-family: Montreal;
	font-style: italic;
	font-weight: 500;
	src: url('../public/fonts/neue_montreal/500i.woff') format('woff');
}

@font-face {
	font-family: Montreal;
	font-weight: 600;
	src: url('../public/fonts/neue_montreal/600.woff') format('woff');
}

@font-face {
	font-family: Montreal;
	font-style: italic;
	font-weight: 600;
	src: url('../public/fonts/neue_montreal/600i.woff') format('woff');
}

@font-face {
	font-family: Montreal;
	font-weight: 700;
	src: url('../public/fonts/neue_montreal/700.woff') format('woff');
}

@font-face {
	font-family: Montreal;
	font-style: italic;
	font-weight: 700;
	src: url('../public/fonts/neue_montreal/700i.woff') format('woff');
}

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: Montreal, -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica, Arial, Verdana, sans-serif;
}

#root {
	display: flex;
	flex-direction: column;
	height: 100vh;
	margin: auto;
}

fieldset {
	border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	box-shadow: 0 0 0 1000px #ffffff inset !important;
}
