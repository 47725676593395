@import '~styleguide/variables';

.LoginHeader {
	display: block;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	padding: 0 1rem;

	&__left,
	&__right {
		display: flex;
		align-items: center;
		height: 100%;
	}

	&__right {
		justify-content: flex-end;
	}

	&__logo {
		width: 100px;
		height: 100%;
		padding: 14px 0;
		background-image: url('./images/logo.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	@media all and (min-width: 1000px) {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
	}
}
