@mixin chevron-left($color) {
  background-image: url('data:image/svg+xml;utf8,<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.71047 0.70998C6.32047 0.31998 5.69047 0.31998 5.30047 0.70998L0.710469 5.29998C0.320469 5.68998 0.320469 6.31998 0.710469 6.70998L5.30047 11.3C5.69047 11.69 6.32047 11.69 6.71047 11.3C7.10047 10.91 7.10047 10.28 6.71047 9.88998L2.83047 5.99998L6.71047 2.11998C7.10047 1.72998 7.09047 1.08998 6.71047 0.70998Z" fill="#{$color}"/></svg>');
}

@mixin chevron-right($color) {
  background-image: url('data:image/svg+xml;utf8,<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.29055 0.710022C0.900547 1.10002 0.900547 1.73002 1.29055 2.12002L5.17055 6.00002L1.29055 9.88002C0.900547 10.27 0.900547 10.9 1.29055 11.29C1.68055 11.68 2.31055 11.68 2.70055 11.29L7.29055 6.70002C7.68055 6.31002 7.68055 5.68002 7.29055 5.29002L2.70055 0.700022C2.32055 0.320022 1.68055 0.320022 1.29055 0.710022Z" fill="#{$color}"/></svg>');
}

@mixin focus() {
  // https://www.tpgi.com/focus-visible-and-backwards-compatibility/

  &:focus {
    // Browsers not suporting focus-visible: Safari
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 5px;
  }

  &:focus:not(:focus-visible) {
    // Resetting :focus styles in browsers suporting focus-visible: Firefox and Chrome
    outline: none;
  }

  &:focus-visible {
    // Styles in browsers suporting focus-visible: Firefox and Chrome
    // This is visible when focusing via keyboard
    outline: 1px dotted ButtonText;
    outline: -webkit-focus-ring-color auto 1px;
  }
}
