@import '~styleguide/variables';

.LoginPage__gridItem {
	@media screen and (min-width: $breakpoint--lg) {
		grid-column-start: 6;
	}
}

.LoginPage {
	width: 100%;
	max-width: 500px;
	margin: 12px auto 0;
}

.LoginPage__inlineCta {
	font-weight: 500;
}
