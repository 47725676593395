.AddOwnerCard {
	position: relative;
	top: 40px;

	&__header {
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}

	&__content {
		min-width: 490px;
		margin: auto;
	}
}

.Form {
	&__fieldset {
		padding-right: 0;
		padding-bottom: 24px;
		padding-left: 0;

		&--about {
			composes: Form__fieldset;
			margin-bottom: 8px;
		}
	}
}

.Address__row,
.Passport__row {
	display: grid;
	grid-gap: 12px;
	grid-template-columns: repeat(auto-fill, minmax(204px, 1fr));
	margin-bottom: 12px;
}
