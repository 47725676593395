.CompanyAddress {
	min-width: 500px;

	&__form {
		width: 475px;
	}

	&__cta {
		margin-top: 40px;
	}
}
