@import '~styleguide/variables';

.Notification {
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
  padding: 18px 22px;
  animation: fade-in 0.3s;
  border-radius: 8px;
  animation-fill-mode: forwards;

  &__content {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }

  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  &__description {
    overflow: auto;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
  }

  &--negative {
    background: $red--50;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      margin: 2px 18px 2px 0;
      background-image: url('./x-circle.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    .Notification__content {
      max-width: calc(100% - 38px);
    }

    .Notification__title,
    .Notification__description {
      color: $red--600;
      text-align: left;
    }
  }

  &--positive {
    background: $green--50;

    .Notification__title,
    .Notification__description {
      color: $green--600;
    }
  }

  .Notification__title + .Notification__description {
    text-align: left;
  }
}
