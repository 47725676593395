@import '~styleguide/variables';

.PaymentType {
	display: flex;
	position: relative;
	align-items: center;
	height: 64px;
	cursor: pointer;

	&::after {
		content: url('/icons/chevron-right.svg');
		position: absolute;
		right: 20px;
	}
}

.PaymentType__text {
	display: flex;
	flex: 1 1;
	align-items: center;
	height: 100%;
	margin-left: 20px;
	border-bottom: 1px solid $gray--100;
}
