@import '~styleguide/variables';
@import '~styleguide/utilities/functions';

@mixin chevron-down($color) {
	background-image: url('data:image/svg+xml;utf8,<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.2902 0.2925C10.9002 -0.0975 10.2702 -0.0975 9.88022 0.2925L6.00022 4.1725L2.12022 0.2925C1.73022 -0.0975 1.10022 -0.0975 0.71022 0.2925C0.32022 0.6825 0.32022 1.3125 0.71022 1.7025L5.30022 6.2925C5.69022 6.6825 6.32022 6.6825 6.71022 6.2925L11.3002 1.7025C11.6802 1.3225 11.6802 0.6825 11.2902 0.2925Z" fill="#{$color}"/></svg>');
}

.CardsSelect {
	position: relative;

	&__input {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 64px;
		overflow: hidden;
		border-radius: 8px;
		outline: none;
		box-shadow: inset 0 0 0 1px $gray--200;
		cursor: pointer;

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 29px;
			right: 26px;
			width: 12px;
			height: 7px;
			background-repeat: no-repeat;
			pointer-events: none;
			@include chevron-down(encode-color($gray--500));
		}

		&:focus-within,
		&:hover {
			box-shadow: inset 0 0 0 2px $black;

			&::after {
				@include chevron-down(encode-color($black));
			}
		}

		&--expanded {
			box-shadow: inset 0 0 0 2px $black;

			&::after {
				@include chevron-down(encode-color($black));
			}
		}

		&--disabled {
			&::after {
				background: none;
			}

			&:focus-within,
			&:hover {
				box-shadow: inset 0 0 0 1px $gray--200;

				&::after {
					background: none;
				}
			}

			.CardsSelect__item {
				cursor: not-allowed;
			}
		}
	}

	&__dropdown {
		display: flex;
		position: absolute;
		z-index: 1;
		flex-direction: column;
		width: 100%;
		margin-top: 4px;
		overflow: hidden;
		border-radius: 8px;
		background-color: $white;
		box-shadow: $shadow;
		button:hover,
		button:focus {
			background-color: $gray--100;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		max-height: 128px;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	&__item {
		flex-shrink: 0;
		width: 100%;
		min-height: 64px;
		padding: 16px 22px 12px;
		border: none;
		outline: none;
		background: none;
		text-align: left;
		cursor: pointer;
	}

	&__cta {
		width: 100%;
		padding: 24px 0;
		padding-left: 20px;
		border: none;
		outline: none;
		background: none;
		text-align: left;
		cursor: pointer;
	}
}
