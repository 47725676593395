@import '~styleguide/variables';

.ModalInviteMemberSent {
	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__body {
		margin-bottom: 24px;
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 16px;
		text-align: right;
	}

	&__cta {
		margin-left: 12px;
	}
}
