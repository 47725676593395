@import '~styleguide/variables';

.Loader {
  &__dot {
    display: block;
    position: absolute;
    top: calc(50% - 4px);
    width: 8px;
    height: 8px;
    transform: scale(0);
    animation: loadingDots 1.5s infinite linear;
    border-radius: 50%;
    background-color: $color-primary;

    &--secondary,
    &--tertiary,
    &--ctaLink,
    &--inlineLink {
      background-color: $gray--500;
    }

    &--first {
      composes: Loader__dot;
      left: calc(50% - 16px);
      animation-delay: 0s;
    }
    &--second {
      composes: Loader__dot;
      left: calc(50% - 4px);
      animation-delay: 0.25s;
    }
    &--third {
      composes: Loader__dot;
      left: calc(50% + 8px);
      animation-delay: 0.5s;
    }
  }
}

@keyframes loadingDots {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
  60%,
  100% {
    transform: scale(0);
  }
}
