@import '~styleguide/variables';

.Form {
	max-width: 468px;
}

.CompanyInfo {
	&__programName {
		position: relative;
	}

	&__info {
		position: absolute;
		top: 0;
		left: 100%;
		transform: translateY(50%);
		cursor: pointer;
	}

	&__infoIcon {
		width: 24px;
		height: 24px;
		margin-left: 4px;
		background-image: url('./images/info.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	&__programWebsite {
		display: flex;
		align-items: center;
	}

	&__checkbox {
		flex-shrink: 0;
		margin-left: 20px;
	}
}
