@import '~styleguide/variables';

.NetworkSelector {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	max-width: 224px;
	height: 124px;
	padding: 16px 20px 24px;
	border: 1px solid $gray--200;
	border-radius: 8px;
	cursor: pointer;

	&--active,
	:focus {
		@extend .NetworkSelector;

		border: 1px solid $color-primary;
	}

	&__right {
		text-align: right;
	}

	&__input {
		position: absolute;
		width: 0;
		height: 0;
		opacity: 0;
	}

	&__title {
		text-transform: capitalize;
	}

	&__icon {
		width: 80px;
		height: 48px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		&--mastercard {
			background-image: url('./images/mastercard.svg');
			composes: NetworkSelector__icon;
		}

		&--visa {
			background-image: url('./images/visa.svg');
			composes: NetworkSelector__icon;
		}
	}
}
