@import '~styleguide/variables';

.TeamHeader {
	display: flex;
	justify-content: flex-start;
	margin-top: 34px;
	margin-bottom: 24px;
	padding-bottom: 10px;
	border-bottom: 1px solid $gray--100;
}
