@import '~styleguide/variables';
@import '~styleguide/utilities/mixins';

.Root {
	position: relative;
}

.Label {
	display: inline-block;
	box-sizing: border-box;
	padding: 12px 24px;
	border-radius: 100px;
	background: $gray--100;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	cursor: pointer;

	&:hover,
	&:focus {
		border-color: $black;
		background-color: $black;
		color: $white;
	}

	@include focus();
}

.Input {
	visibility: hidden;
	position: absolute;
	left: 0;
	width: 0;
	height: 0;
}

.Preview {
	display: inline-block;
	margin-top: 8px;
	margin-left: 8px;
	font-size: 14px;
}
