@import '~styleguide/variables';

.Label {
  display: inline-block;

  &--large {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.03em;
  }

  &--medium {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
  }

  &--small {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
  }

  &--x-small {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  &--primary {
    color: $text__color;
  }

  &--secondary {
    color: $text__color--secondary;
  }

  &--tertiary {
    color: $text__color--tertiary;
  }

  &--disabled {
    color: $text__color--disabled;
  }

  &--accent {
    color: $color-primary;
  }

  &--positive {
    color: $text__color--positive;
  }

  &--negative {
    color: $color-negative;
  }

  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--justified {
    text-align: justify;
  }

  &--400 {
    font-weight: 400;
  }

  &--500 {
    font-weight: 500;
  }

  &--600 {
    font-weight: 600;
  }

  &--700 {
    font-weight: 700;
  }
}
