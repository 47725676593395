@import '~styleguide/variables';

.Tabs {
  display: flex;
  margin-top: 8px;
  border-bottom: 2px solid $gray--100;

  &__link {
    display: inline-block;
    position: relative;
    top: 2px;
    margin-right: 24px;
    padding-bottom: 16px;
    color: $text__color--tertiary;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-decoration: none;

    &:first-child {
      padding-left: 0.1rem;
    }

    &:hover,
    &:focus {
      box-shadow: inset 0 -4px 0 0 $color-primary;
    }

    &.isActive {
      box-shadow: inset 0 -4px 0 0 $color-primary;
      color: $text__color;
    }
  }
}
