@import '~styleguide/variables';

.DetailBox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 64px;
	border-bottom: 1px solid $gray--100;
	text-transform: capitalize;
}
