@import '~styleguide/variables';

/* wrappers */
.Switch {
  display: inline-block;
  width: 64px;
  height: 30px;
}

.Switch__label {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 30px;
  border-radius: 100px;
  cursor: pointer;
}

.Switch.is-disabled .Switch__label {
  cursor: not-allowed;
}

/* actual checkbox */
.Switch__input {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  border: none;
  background: transparent;
  appearance: none;
  cursor: pointer;
}

/* switch background */
.Switch__toggle {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 64px;
  height: 30px;
  padding: 1px;
  border-radius: 100px;
  background-color: $gray--100;
  line-height: 30px;
  white-space: nowrap;
}

.Switch__input:checked ~ .Switch__toggle {
  background-color: $color-primary;
  transition: background-color 0.5s ease;
}

.Switch.is-disabled ~ .Switch__toggle,
.Switch.is-disabled .Switch__input:checked ~ .Switch__toggle {
  background-color: $gray--100;
}

/* spans with text on and off */
.Switch__on {
  position: absolute;
  left: 10px;
  color: $white;
}
.Switch__off {
  position: absolute;
  right: 10px;
  color: $text__color;
}
.Switch__input:not(:checked) ~ .Switch__toggle .Switch__on {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.Switch__input:not(:checked) ~ .Switch__toggle .Switch__off {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.Switch__input:checked ~ .Switch__toggle .Switch__on {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.Switch__input:checked ~ .Switch__toggle .Switch__off {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.Switch.is-disabled .Switch__on,
.Switch.is-disabled .Switch__off {
  color: $text__color--disabled;
}

/* white circle */
.Switch__button {
  content: '';
  display: block;
  position: absolute;
  left: 2px;
  height: 26px;
  width: 26px;
  background-color: $white;
  border-radius: 50%;
  box-shadow: 0px 2.7px 0.9px 0px rgba($black, 0.06), 0px 2.7px 7.2px 0px rgba($black, 0.15);
  transition: transform 0.5s ease;
}

.Switch__input:not(:checked) ~ .Switch__toggle .Switch__button {
  transform: translateX(0);
}

.Switch__input:checked ~ .Switch__toggle .Switch__button {
  transform: translateX(34px);
}
