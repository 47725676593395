@import '~styleguide/variables';

.ConfirmationStep {
	width: 100%;
	border: 1px solid $gray--100;

	@media all and (min-width: $responsive-tablet) {
		border-radius: 16px;
	}
}

.Card {
	padding: 28px 24px 40px;
}

.Card__text {
	display: inline-block;
	margin: 14px 0;
}

.Card__cta {
	width: 100%;
	margin-top: 24px;
}
