@import '~styleguide/variables';
@import '~styleguide/utilities/functions';

@mixin arrow-right($color) {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M7.00004 0.333374L5.82504 1.50837L10.475 6.16671H0.333374V7.83337H10.475L5.82504 12.4917L7.00004 13.6667L13.6667 7.00004L7.00004 0.333374Z" fill="#{$color}"/></svg>');
}

@mixin chevron-right($color) {
  background-image: url('data:image/svg+xml;utf8,<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z" fill="#{$color}"/></svg>');
}

.Pill {
  display: inline-block;
  align-items: center;
  padding: 15px 23px;
  border: 1px solid $color-primary;
  border-radius: 100px;
  background: $color-primary;
  color: $white;
  font-family: $font-family;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &:focus,
  &:hover {
    border-color: $black;
    outline: none;
    background-color: $black;
  }

  &:active {
    border-color: $gray--700;
    background-color: $gray--700;
  }

  &:disabled,
  &.isDisabled {
    border-color: $gray--50;
    background: $gray--50;
    color: $text__color--disabled;
    cursor: not-allowed;

    &:active {
      pointer-events: none;
    }
  }

  &.isLoading {
    display: inline-block;
    position: relative;
    border-color: rgb(180, 210, 255);
    background-color: rgb(180, 210, 255);
    vertical-align: middle;
    cursor: progress;

    .Pill__text {
      display: flex;
      visibility: hidden;
      opacity: 0;
    }
  }
}

.Pill--medium {
  composes: Pill;
  padding: 13px 19px;
  font-size: 16px;
  line-height: 20px;
}

.Pill--small {
  composes: Pill;
  padding: 9px 15px;
  font-size: 14px;
  line-height: 16px;
}

.Pill--secondary {
  composes: Pill;
  border-color: $gray--100;
  background: $gray--100;
  color: $black;

  &:active,
  &:focus,
  &:hover {
    color: $white;
  }

  &:disabled:hover,
  &.isDisabled:hover {
    color: $text__color--disabled;
  }

  &.isLoading {
    border-color: $gray--100;
    background: $gray--100;
    color: $text__color--disabled;
  }
}

.Pill--secondary-outlined {
  composes: Pill;
  border: 1px solid $black;
  background: $white;
  color: $black;

  &:active,
  &:focus,
  &:hover {
    color: $white;
  }

  &:disabled,
  &.isDisabled,
  &:disabled:hover,
  &.isDisabled:hover,
  &.isLoading {
    border-color: $gray--200;
    background-color: $white;
    color: $text__color--disabled;
  }
}

.Pill--tertiary {
  composes: Pill;
  border-color: $white;
  background: $white;
  color: $black;

  &:focus,
  &:hover {
    border-color: $gray--100;
    background-color: $gray--100;
    color: $black;
  }

  &:active {
    border-color: $gray--200;
    background-color: $gray--200;
    color: $black;
  }

  &:disabled,
  &.isDisabled {
    border-color: $white;
    background: $white;
    color: $text__color--disabled;
  }

  &.isLoading {
    border-color: $white;
    background: $white;
    color: $text__color--disabled;
  }
}

.Pill--tertiary-outlined {
  composes: Pill;
  border-color: $gray--300;
  background: $white;
  color: $black;

  &:focus,
  &:hover {
    border-color: $black;
    background-color: $white;
    box-shadow: inset 0 0 0 1px $black;
    color: $black;
  }

  &:active {
    border-color: $black;
    background-color: $gray--100;
    box-shadow: inset 0 0 0 1px $black;
    color: $black;
  }

  &:disabled,
  &.isDisabled {
    border-color: $gray--200;
    background: $white;
    box-shadow: none;
    color: $text__color--disabled;
  }

  &.isLoading {
    border-color: $gray--200;
    background: $white;
    color: $text__color--disabled;

    &:hover {
      box-shadow: none;
      color: $text__color--disabled;
    }
  }
}

.Pill--hasArrow {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &::after {
    @include arrow-right(encode-color($white));

    content: '';
    width: 16px;
    height: 16px;
    margin-left: 60px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:disabled,
  &.isDisabled {
    &::after {
      @include arrow-right(encode-color($gray--400));
    }
  }

  &.isLoading {
    &::after {
      background-image: initial;
    }

    .Pill__text {
      &::after {
        content: '';
        grid-area: 1 / 1;
        width: 14px;
        height: 14px;
        margin-left: 60px;
      }
    }
  }

  &.Pill--medium {
    &::after {
      width: 14px;
      height: 14px;
    }
  }

  &.Pill--small {
    &::after {
      width: 12px;
      height: 12px;
    }
  }
}

.Pill--secondary.Pill--hasArrow,
.Pill--secondary-outlined.Pill--hasArrow {
  display: inline-flex;
  align-items: center;

  &::after {
    @include arrow-right(encode-color($black));
  }

  &:focus,
  &:hover,
  &:active {
    &::after {
      @include arrow-right(encode-color($white));
    }
  }

  &:disabled,
  &.isDisabled {
    &::after {
      @include arrow-right(encode-color($gray--400));
    }
  }

  &.isLoading {
    display: inline-block;

    &::after {
      background-image: initial;
    }
  }
}

.Pill--ctaLink {
  display: inline-flex;
  height: auto;
  padding: 0;
  border: none;
  background-color: transparent;
  color: $color-primary;
  font-weight: 400;

  &::after {
    @include chevron-right(encode-color($color-primary));

    content: '';
    display: inline-block;
    width: 8px;
    height: 12px;
    margin-left: 12px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:focus,
  &:hover {
    outline: none;
    background-color: transparent;
    color: $black;

    &::after {
      @include chevron-right(encode-color($black));
    }
  }

  &:active {
    outline: none;
    background-color: transparent;
    color: $gray--700;

    &::after {
      @include chevron-right(encode-color($gray--700));
    }
  }

  &:disabled,
  &.isDisabled,
  &.isLoading {
    outline: none;
    background-color: transparent;
    color: $text__color--disabled;

    &::after {
      @include chevron-right(encode-color($text__color--disabled));
    }
  }

  &.isLoading {
    display: inline-flex;
  }

  &.Pill--medium {
    line-height: 24px;
  }

  &.Pill--small {
    line-height: 20px;
  }
}

.Pill--inlineLink {
  height: auto;
  padding: 0;
  border: none;
  border-bottom: 1px solid currentColor;
  border-radius: 0;
  background-color: transparent;
  color: $black;
  font-weight: 400;

  &:focus,
  &:hover {
    border-bottom-color: currentColor;
    outline: none;
    background-color: transparent;
    color: $gray--500;
  }

  &:active {
    border-bottom-color: currentColor;
    outline: none;
    background-color: transparent;
    color: $gray--700;
  }

  &:disabled,
  &.isDisabled,
  &.isLoading {
    border-bottom-color: currentColor;
    outline: none;
    background-color: transparent;
    color: $text__color--disabled;
  }

  &.Pill--medium {
    line-height: 24px;
  }

  &.Pill--small {
    line-height: 20px;
  }
}
