.PendingInvites__spinner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.PendingInvites__emptyInitials {
	display: flex;
	align-items: center;

	span {
		margin-left: 12px;
	}
}

.PendingInvites__table {
	margin-bottom: 28px;

	tr td:nth-of-type(2) {
		width: 35%;
	}
}
