.CredentialBlock {
	&__titleWrap {
		align-items: center;
		justify-content: flex-start;
		display: flex;
	}

	&__contentWrap {
		margin-top: 12px;
		padding-bottom: 24px;
		border-bottom: 1px solid #eee;
	}
}
