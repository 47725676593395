@import '~styleguide/variables';
@import '~styleguide/utilities/functions';

@mixin chevron-down($color) {
	background-image: url('data:image/svg+xml;utf8,<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.2902 0.2925C10.9002 -0.0975 10.2702 -0.0975 9.88022 0.2925L6.00022 4.1725L2.12022 0.2925C1.73022 -0.0975 1.10022 -0.0975 0.71022 0.2925C0.32022 0.6825 0.32022 1.3125 0.71022 1.7025L5.30022 6.2925C5.69022 6.6825 6.32022 6.6825 6.71022 6.2925L11.3002 1.7025C11.6802 1.3225 11.6802 0.6825 11.2902 0.2925Z" fill="#{$color}"/></svg>');
}

.ModalInviteMemberForm {
	margin-top: 30px;

	&__body {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 24px 0;
	}

	&__tagsInput {
		flex-grow: 1;
	}

	&__dropdown {
		position: relative;
		margin-left: 14px;
		padding: 16px 48px 16px 20px;
		border: 1px solid $gray--50;
		border-radius: 8px;
		background: $gray--50;

		&--disabled {
			color: $text__color--disabled;
			cursor: not-allowed;
			composes: ModalInviteMemberForm__dropdown;

			&::after {
				content: '';
				display: block;
				position: absolute;
				top: calc(50% - 3px);
				right: 18px;
				width: 12px;
				height: 7px;
				background-repeat: no-repeat;
				pointer-events: none;
				@include chevron-down(encode-color($gray--500));
			}
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 16px;
		text-align: right;
	}

	.TagsInput {
		border-radius: 8px;
		background: $gray--50;

		div {
			margin-top: 0 !important;
		}

		input {
			background-color: transparent !important;
		}
	}
}
