@import '~styleguide/variables';

.DrawerModal {
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
}

.DrawerModal__intro--mobile {
  @media screen and (min-width: $breakpoint--md) {
    display: none;
  }
}

.DrawerModal__intro--desktop {
  display: none;

  @media screen and (min-width: $breakpoint--md) {
    display: initial;
    margin: 24px 80px 24px 60px;
  }

  @media screen and (min-width: $breakpoint--lg) {
    margin: 24px 135px 24px 64px;
  }
}

.DrawerModal__drawer {
  flex: 1 1;
  width: 100%;
  min-height: 100vh;
  padding: 24px 20px;

  @media screen and (min-width: $breakpoint--md) {
    min-width: 48%;
    padding: 94px 20px 68px;
    box-shadow: -6px 0 16px -6px rgba(0, 0, 0, 0.12);
  }

  @media screen and (min-width: $breakpoint--lg) {
    padding: 94px 80px 68px;
  }
}

.DrawerModal__close {
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  border-radius: 50%;
  background-color: transparent;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.DrawerModal__close--mobile {
  composes: DrawerModal__close;

  @media screen and (min-width: $breakpoint--md) {
    display: none;
  }
}

.DrawerModal__close--desktop {
  composes: DrawerModal__close;
  display: none;

  @media screen and (min-width: $breakpoint--md) {
    display: initial;
  }
}
