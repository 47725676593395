@import '~styleguide/variables';

.LoadFundsPage {
	display: flex;
	flex: 1;
	flex-direction: row;

	&__aside {
		display: none;
		flex-direction: column;
		width: 348px;
		padding: 54px 73px;

		@media all and (min-width: $responsive-tablet) {
			display: flex;
		}
	}

	&__content {
		width: 100%;
		margin: 24.5px 0;

		@media all and (min-width: $responsive-tablet) {
			max-width: 372px;
		}

		@media all and (min-width: $responsive-desktop) {
			margin-right: 186px;
			margin-left: 186px;
		}
	}
}
