@import '~styleguide/variables';

.TransactionPageSuccess {
	&__details {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
		gap: 20px;
	}

	&__descriptionItem {
		margin-top: 0;
		margin-right: 0;
	}
}
