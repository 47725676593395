@import '~styleguide/variables';

.LoadFundsLearnMore {
	padding: 0 24px;

	&__text {
		color: $black;
		font-size: 14px;
		font-weight: normal;
		line-height: 20px;
		text-decoration: underline;
	}
}
