@import '~styleguide/variables';

.Overview {
	padding: 24px 0;
	border-bottom: 1px solid $gray--100;

	&__banner {
		padding: 24px;
		border-radius: 16px;
		background: $gray--50;
	}
}
