@import '~styleguide/variables';

.DeletePaymentMethod {
	display: flex;
	flex-direction: column;
	min-height: 136px;
	margin-top: 30px;
}

.DeletePaymentMethod__paragraph {
	padding: 12px 0 24px;
}

.DeletePaymentMethod__buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;
}

.DeletePaymentMethod__button {
	margin-left: 16px;
}

.PendingDeletePaymentMethod {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 136px;
}
