@import '~styleguide/variables';

.Company {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.Company__logo {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 8px;
	background: $blue--100;
}

.Company__char {
	color: $blue--700;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
}

.Company__name {
	width: 100%;
	margin-left: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
