@import '~styleguide/variables';

.ResendSMS__successText {
	color: #00df90; // TODO: update green-400 to match this hex

	img {
		margin-left: 2px;
		vertical-align: top;
	}
}
