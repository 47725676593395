@import '~styleguide/variables';

.Grid {
  display: grid;
  grid-column-gap: 2.778%;

  &--outer {
    margin-right: 20px;
    margin-left: 20px;
  }
}

@for $i from 1 through 16 {
  .Grid--#{$i} {
    grid-template-columns: repeat(#{$i}, 1fr);
  }
}

@media screen and (min-width: $breakpoint--lg) {
  @for $i from 1 through 16 {
    .Grid-lg--#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);
    }
  }
}
