@import '~styleguide/variables';

.ModalBeforeGetStarted {
	padding: 30px 0 0 0;

	.Warning {
		padding: 20px;
		border-radius: 8px;
		background-color: $red--50;
		color: $red--600;

		&__heading {
			color: $red--600;
		}

		&__list {
			margin: 8px 0 0;
			padding-left: 24px;
			font-size: 16px;
			line-height: 24px;
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 40px;
		padding-top: 16px;

		> *:first-child {
			margin-right: 16px;
		}
	}
}
