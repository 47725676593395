/* === COLORS === */

/* Neutral */
$white: #ffffff;
$gray--50: #f6f6f6;
$gray--100: #eeeeee;
$gray--200: #e2e2e2;
$gray--300: #cbcbcb;
$gray--400: #afafaf;
$gray--500: #757575;
$gray--600: #545454;
$gray--700: #333333;
$gray--800: #1f1f1f;
$gray--900: #1a1a1a;
$black: #000000;

/* Accents */
$purple--50: #f2edff;
$purple--100: #dfd3ff;
$purple--200: #b99eff;
$purple--300: #8657ff;
$purple--400: #6022ff;
$purple--500: #491ac2;
$purple--600: #3a1499;
$purple--700: #260e66;

$blue--50: #ebf1ff;
$blue--100: #ccddff;
$blue--200: #8fb4ff;
$blue--300: #3d7eff;
$blue--400: #0055ff;
$blue--500: #0041c2;
$blue--600: #003399;
$blue--700: #002266;

$green--50: #ebfcf6;
$green--100: #ccf9e9;
$green--200: #8ff1ce;
$green--300: #73c496;
$green--400: #00df90;
$green--500: #00a96d;
$green--600: #008656;
$green--700: #00593a;

$yellow--50: #fffaf1;
$yellow--100: #fff2dc;
$yellow--200: #ffe3b2;
$yellow--300: #ffcf7a;
$yellow--400: #ffc050;
$yellow--500: #c2923d;
$yellow--600: #997330;
$yellow--700: #664d20;

$orange--50: #fff5f0;
$orange--100: #ffe5da;
$orange--200: #ffc5ad;
$orange--300: #ff9b71;
$orange--400: #ff7c44;
$orange--500: #c25e34;
$orange--600: #994a29;
$orange--700: #66321b;

$red--50: #fef1ef;
$red--100: #fdddd7;
$red--200: #fab4a6;
$red--300: #f67e65;
$red--400: #f35535;
$red--500: #b94128;
$red--600: #913122;
$red--700: #923320;

/* Theme */
$color-primary: $blue--400;
$color-negative: $red--400;
$color-positive: $green--400;

$shadow: 0 3px 24px rgba($black, 0.08), 0 0 4px rgba($black, 0.03);

/* === TYPE === */
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
  'Helvetica Neue', sans-serif;

$text__color: $black;
$text__color--secondary: $gray--600;
$text__color--tertiary: $gray--500;
$text__color--disabled: $gray--400;
$text__color--positive: $green--500;

/* === BREAKPOINTS === */
$responsive-tablet: 700px;
$responsive-desktop: 1400px;

$breakpoint--xs: 0;
$breakpoint--sm: 576px;
$breakpoint--md: 768px;
$breakpoint--lg: 992px;
$breakpoint--xl: 1200px;
