@import '~styleguide/variables';

.Header {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	padding-bottom: 20px;
	row-gap: 20px;
	border-bottom: 1px solid $gray--100;
	margin-bottom: 40px;

	@media all and (min-width: $breakpoint--sm) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
	}

	&__right {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		@media all and (min-width: $breakpoint--sm) {
			flex-direction: column;
			align-items: flex-end;
		}

		@media all and (min-width: $breakpoint--md) {
			flex-direction: row;
			align-items: flex-start;
			justify-content: flex-end;
		}
	}

	&__descriptionItem {
		text-align: right;

		&:first-child {
			margin-right: 16px;
			@media all and (min-width: $breakpoint--sm) {
				margin-right: 0;
				margin-bottom: 16px;
			}
			@media all and (min-width: $breakpoint--md) {
				margin-right: 72px;
				margin-bottom: 0;
			}
		}
	}
}
