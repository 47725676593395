@import '~styleguide/variables';

.CardDesign {
	&__section {
		margin: 20px 0;
		padding-top: 20px;
		border-top: 1px solid $gray--200;
	}

	&__submit {
		&--tablet {
			display: none;
			margin: 40px 0;

			@media all and (min-width: $responsive-tablet) {
				display: block;
			}
		}

		&--mobile {
			margin: 22px 0;

			@media all and (min-width: $responsive-tablet) {
				display: none;
			}
		}
	}

	.Content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media all and (min-width: $responsive-tablet) {
			flex-direction: row;
		}

		&__left {
			min-width: 340px;
		}

		&__right {
			width: 100%;
			margin-top: 32px;

			@media all and (min-width: $responsive-tablet) {
				flex-grow: 1;
				min-width: 350px;
				max-width: 480px;
				margin-top: 0;
				margin-left: 120px;
			}
		}
	}

	.NetworkSelector__wrapper {
		display: grid;
		grid-column-gap: 40px;
		grid-template-columns: 1fr 1fr;
	}

	.Logo__text {
		display: block;
		margin-bottom: 22px;
		font-size: 14px;
		line-height: 20px;
	}
}
