.ModalAddSignedPayloadsKey {
	display: flex;
	flex-direction: column;
	margin-top: 30px;

	&__footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 40px;
		padding-top: 16px;
	}
}
