:root {
	--toastify-color-info: #ebf1ff;
	--toastify-color-success: #ebfcf6;

	/* --toastify-color-warning: #f1c40f; */
	--toastify-color-error: #fef1ef;

	/* --toastify-toast-width: 80%; */
	--toastify-text-color-info: #0055ff;
	--toastify-text-color-success: #008656;

	/* --toastify-text-color-warning: #ffffff; */
	--toastify-text-color-error: #913122;
}

.toast {
	border-radius: 8px;
	box-shadow: none;
}

.Toastify__toast--success .Toastify__close-button {
	color: #008656;
}

.Toastify__toast--error .Toastify__close-button {
	color: #913122;
}

.Toastify__toast--info .Toastify__close-button {
	color: #0055ff;
}

.Toastify__toast-body {
	align-items: flex-start;
	padding: 12px 20px;
	font-size: 16px;
	line-height: 24px;
}

.Toastify__toast-icon {
	margin-top: 2px;
}
