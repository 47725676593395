@import '~styleguide/variables';
@import '~styleguide/utilities/mixins';

.Modal {
  display: flex;
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  animation: fade-in 0.3s;
  background: rgba($black, 0.7);
  animation-fill-mode: forwards;

  &_isFadingOut {
    animation: fade-out 0.3s; // This must be synchronized to the ANIMATION_DURATION const defined in Modal.tsx
    animation-fill-mode: forwards;
  }

  .Content {
    width: 100%;
    padding: 24px 20px 16px 20px;
    background: white;
    box-shadow: 0 3px 48px rgba($black, 0.08), 0 0 8px rgba($black, 0.03);

    .Modal_Close {
      display: block;
      width: 24px;
      height: 24px;
      padding: 0;
      overflow: hidden; // one white px band when hovering
      transition: transform 0.3s;
      border: 1px solid transparent;
      border: none;
      background: none;
      cursor: pointer;

      &:hover,
      &:focus {
        transform: rotate(-180deg);
        transform-origin: center center;
      }

      @include focus();
    }
  }

  @media all and (min-width: $responsive-tablet) {
    .Content {
      max-width: 600px;
      border-radius: 16px;
    }
  }
}

//TODO(iago): We should extract all css utilities to a shared file and find a way to make it work with styleguidist
@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
