@import '~styleguide/variables';

.OwnersStep {
	&__description {
		display: inline-block;
		margin-bottom: 14px;
		font-size: 14px;
		line-height: 20px;
	}
}
